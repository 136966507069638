import * as React from 'react';
import Icon from '@material-ui/core/Icon';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Store from '../store/store';
const styles = require('./styles/workflow_styles.scss');
import { observer } from "mobx-react";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import axios from 'axios';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IDBWfTask } from './interfaces/IWorkflow';

// let stylesColumn

interface State {
  reload?: boolean,
  stateProject?: string,
  dateStartProject?: boolean,
  enPreparation?: boolean,
  enCours?: boolean,
  terminado?: boolean,
  refuse?: boolean,
  sortTasks: IDBWfTask[],
  unselectedRows?: number[];
  allTasks: IDBWfTask[];
  stateMessageTab: string;
}

const Window: any = window;

@observer
class AllTasksUsers extends React.Component<RouteComponentProps<any>, State>{
  public constructor(props: any) {
    super(props);
    this.state = {
      reload: false,
      stateProject: "",
      enPreparation: Window.MY_TASKS_CONFIG.find(x => x == "En Préparation") != undefined ? true : false,
      enCours:  Window.MY_TASKS_CONFIG.find(x => x == "En Cours") != undefined ? true : false,
      terminado:  Window.MY_TASKS_CONFIG.find(x => x == "Terminé") != undefined ? true : false,
      refuse:  Window.MY_TASKS_CONFIG.find(x => x == "Refusé") != undefined ? true : false,
      allTasks: [],
      sortTasks: [],
      unselectedRows: [],
      stateMessageTab: "Récupération des tâches en cours...",
    }
  }

  public componentDidMount() {
    this.getAllTasksByUser();
  }

  public convertDateFromDataBaseToDisplaying = (cell, row: IDBWfTask) => {
    let dateFormated: string = "";
    if (cell != null) {
      dateFormated = new Date(cell).toLocaleDateString();
    }
    else {
      dateFormated = "Pas de date";
    }

    return dateFormated;
  }
  public convertEstimateDateFromDataBaseToDisplaying = (cell, row: IDBWfTask) => {
    
    const dateEnd: Date = new Date(row.WfStep.WfProject.dateFinSouhaitee);
    try {
      const dateFormated = dateEnd.toLocaleDateString();
      return dateFormated;
    } catch (error) {
      const dateFormated = "Pas de date";
      return dateFormated;
    }
  }
  public displayDocumentName = (cell, row: IDBWfTask) => {
    return <div title={row.WfStep.WfProject.name} style={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>{row.WfStep.WfProject.name}</div>;
    // return `<a href="/document?w=${row.WfStep.WfProject.id}" target="_blank">${row.WfStep.WfProject.Document.title}</a>`;
  }

  public getAllTasksByUser = () => {
    this.setState({ stateMessageTab: "Récupération des tâches en cours..." }, () => {
      axios.get(Store.wsPath + '/1/workflows/getAllTasksAndInfoByUserConnected', {
        params: {
          idUser: Store.userConnected.id
        }
      }).then((responseTask) => {
        const allTask: IDBWfTask[] = responseTask.data;
        let message: string = "Liste des tâches";
        if (responseTask.data.length == 0) {
          message = "Aucune tâche à afficher";
        }
        this.setState({ allTasks: allTask, stateMessageTab: message }, () => {
          this.trierTasks();
        });
      }).catch(error => {
        console.error("Error 'getAllTasksByUser' : ", error);
        this.setState({ stateMessageTab: "Une erreur est survenue lors de la récupération des tâches" });
      });
    });
  }

  public displayTask = (row:IDBWfTask) => {
    // Ici c'est pour passer la tâche en bold , en "vu" :
    if (row.task_view == false) {
      axios.post(Store.wsPath + '/1/workflows/turnTaskViewOff', {
        id: row.id
      })
    }
    const w:string = "w="+row.WfStep.WfProject.id;
    const s:string = "&s="+row.WfStep.id;
    const t: string = "&t="+row.id;
    // Redirection sur le document concerné par la tache
    this.props.history.push('/document?'+w+s+t);
  }

  public projectStatutDisplaying = (cell, row: IDBWfTask) => {
    return row.WfStep.WfProject.state
  }

  public trierTasks = () => {
    const tempStore = this.state.allTasks;
    const tempSortTasks = [];
    if (this.state.enPreparation) {
      tempStore.forEach(task => {
        if (task.state ==  "En préparation") {
          tempSortTasks.push(task);
        }
      });
    }
    if (this.state.enCours) {
      tempStore.forEach(task => {
        if (task.state == "En cours") {
          tempSortTasks.push(task);
          Store.countTaskUser = tempSortTasks.length
        }
      });
    }
    if (this.state.terminado) {
      tempStore.forEach(task => {
        if (task.state == "Terminée") {
          tempSortTasks.push(task);
        }
      });
    }
    if (this.state.refuse) {
      tempStore.forEach(task => {
        if (task.state == "Refusé" || task.state == "Annulé") {
          tempSortTasks.push(task);
        }
      });
    }
    // tempArray.sort((a, b) => a.formatDate - b.formatDate)
    this.setState({ sortTasks: tempSortTasks });
  }

  public onChange = (name: string) => event => {
    // this.getAllTasksByUser()
    if (name == "enPreparation") {
      this.setState({
        enPreparation: event.target.checked
      }, () => { this.trierTasks() })

    } else if (name == "enCours") {
      this.setState({
        enCours: event.target.checked
      }, () => { this.trierTasks() })
    } else if (name == "terminado") {
      this.setState({
        terminado: event.target.checked
      }, () => { this.trierTasks() })
    } else if (name == "refuse") {
      this.setState({
        refuse: event.target.checked
      }, () => { this.trierTasks() })
    }
  }

  public viewTask = (row) => {
    let styleRowUnread = {}
    if (row != undefined && row != null) {
      if ((row.taskview != undefined && row.taskview != null) && (row.taskview == false)) {
        styleRowUnread = { fontWeight: 'bold' }
      }
    }
    return styleRowUnread
  }
  sortTitle(a, b, order) {
    if (order === 'desc') {
      if (a.WfStep.WfProject.Document.title > b.WfStep.WfProject.Document.title) {
        return -1;
      }
      else if (a.WfStep.WfProject.Document.title < b.WfStep.WfProject.Document.title) {
        return 1;
      }
      else
        return 0;
    } else {
      if (a.WfStep.WfProject.Document.title < b.WfStep.WfProject.Document.title) {
        return -1;
      }
      else if (a.WfStep.WfProject.Document.title > b.WfStep.WfProject.Document.title) {
        return 1;
      }
      else
        return 0;
    }
  }
  sortProjectStatut(a, b, order) {
    if (order === 'desc') {
      if (a.WfStep.WfProject.state > b.WfStep.WfProject.state) {
        return -1;
      }
      else if (a.WfStep.WfProject.state < b.WfStep.WfProject.state) {
        return 1;
      }
      else
        return 0;
    } else {
      if (a.WfStep.WfProject.state < b.WfStep.WfProject.state) {
        return -1;
      }
      else if (a.WfStep.WfProject.state > b.WfStep.WfProject.state) {
        return 1;
      }
      else
        return 0;
    }
  }
  sortDateSouhaite(a, b, order) {
    const dateEndA: Date = new Date(a.WfStep.WfProject.dateFinSouhaitee);
    const dateEndB: Date = new Date(b.WfStep.WfProject.dateFinSouhaitee);
    const timestampA = dateEndA.getTime();
    const timeStampB =dateEndB.getTime();
    if (order === 'desc') {
      if (timestampA > timeStampB) {
        return -1;
      }
      else if (timestampA < timeStampB) {
        return 1;
      }
      else
        return 0;
    } else {
      if (timestampA < timeStampB) {
        return -1;
      }
      else if (timestampA > timeStampB) {
        return 1;
      }
      else
        return 0;
    }
  }
  sortDateDone(a, b, order) {
    const dateEndA: Date = new Date(a.dateDone);
    const dateEndB: Date = new Date(b.dateDone);
    const timestampA = dateEndA.getTime();
    const timeStampB =dateEndB.getTime();
    if (order === 'desc') {
      if (timestampA > timeStampB) {
        return -1;
      }
      else if (timestampA < timeStampB) {
        return 1;
      }
      else
        return 0;
    } else {
      if (timestampA < timeStampB) {
        return -1;
      }
      else if (timestampA > timeStampB) {
        return 1;
      }
      else
        return 0;
    }
  }
  getCaret(direction) {
    if (direction === 'asc') {
      return (<Icon style={{fontSize: '26px'}} >arrow_drop_up</Icon>);
    }
    if (direction === 'desc') {
      return (<Icon style={{fontSize: '26px'}} >arrow_drop_down</Icon>);
    }
    return (<span></span>);
  }
  public render() {
    this.state.reload
    const selectRow = {
      // mode: 'checkbox',
      mode: 'radio',
      bgColor: '#10a544',
      clickToSelect: true,
      onSelect: this.displayTask,
      hideSelectColumn: true,
      unselectable: this.state.unselectedRows, // Todo droit ?
    }

    const options = {
      sortIndicator: true,
      defaultSortName: 'formatDateEstimatedEndTask',
      noDataText: this.state.stateMessageTab,
      sizePerPage: 25,
    };

    return (
      <div className={styles.contentFirst}>
        <div className={styles.contentCheckbox} style={{ background: Store.themeParam.backgroundPrimary }}>
          <div>
            <h5>Mes tâches : </h5>
          </div>
          <div className={styles.checkDiv}>
            <FormControlLabel
              className={styles.checkboxFiltre}
              control={
                <Checkbox color="primary" onChange={this.onChange('enPreparation')} checked={this.state.enPreparation} className="checkkkk" />
              }
              label="En préparation"
            />
            <FormControlLabel
              className={styles.checkboxFiltre}
              control={
                <Checkbox
                  color="primary"
                  onChange={this.onChange('enCours')}
                  checked={this.state.enCours}
                />
              }
              label="En cours"
            />
            <FormControlLabel
              className={styles.checkboxFiltre}
              control={
                <Checkbox
                  color="primary"
                  onChange={this.onChange('terminado')}
                  checked={this.state.terminado}
                />
              }
              label="Terminée"
            />
            <FormControlLabel
              className={styles.checkboxFiltre}
              control={
                <Checkbox
                  color="primary"
                  onChange={this.onChange('refuse')}
                  checked={this.state.refuse}
                />
              }
              label="Refusé"
            />
          </div>
        </div>
        {this.state.sortTasks ?
          <div>
            <BootstrapTable
              multiColumnSearch
              data={this.state.sortTasks}
              searchPlaceholder='Rechercher dans la liste'
              search={true}
              options={options}
              selectRow={selectRow}
              className={styles.contentMyTasks}
              // headerStyle={{ zIndex: 220, position: "relative" }}
              pagination version='4'
              // trStyle={this.viewTask}
              size="sm"
            >
              <TableHeaderColumn isKey={true} dataField='id' hidden >id</TableHeaderColumn>
              <TableHeaderColumn dataField='Document' dataFormat={this.displayDocumentName} dataSort={true} sortFunc={this.sortTitle} caretRender={this.getCaret}>Document</TableHeaderColumn>
              <TableHeaderColumn dataField='state' dataSort caretRender={this.getCaret}>Etat de la tâche</TableHeaderColumn>
              <TableHeaderColumn dataField='name' dataSort caretRender={this.getCaret}>Nom de la tâche</TableHeaderColumn>
              <TableHeaderColumn dataField='WfStep' dataFormat={this.projectStatutDisplaying} sortFunc={this.sortProjectStatut} dataSort caretRender={this.getCaret}>Statut du circuit</TableHeaderColumn>
              <TableHeaderColumn dataField='dateFinSouhaitee' dataFormat={this.convertEstimateDateFromDataBaseToDisplaying} dataSort caretRender={this.getCaret} sortFunc={this.sortDateSouhaite} >Date de fin estimée</TableHeaderColumn>
              <TableHeaderColumn dataField='dateDone' dataFormat={this.convertDateFromDataBaseToDisplaying} dataSort caretRender={this.getCaret} sortFunc={this.sortDateDone}>Date de fin réelle</TableHeaderColumn>
              {/* <TableHeaderColumn width='82px' dataField='id' dataFormat={this.toolbox} >Actions</TableHeaderColumn> */}
            </BootstrapTable>
          </div>
          : ""}
      </div>
    )
  }
}


export default withRouter(AllTasksUsers);