import * as React from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { IColumnInfo } from '../interfaces/IRenderForm';

interface SPFieldTextProps {
  field: IColumnInfo;
  value: string;
  readOnly?: boolean;
  onChange?: (question: IColumnInfo, type?: string) => (event: any, newValue?: string) => void;
}

interface SPFieldTextState {

}

class SPFieldText extends React.Component<SPFieldTextProps, SPFieldTextState> {
  constructor(props: SPFieldTextProps) {
    super(props);
    this.state = {};
  }
  render() {
    const onChangeParam:any = this.props.onChange != undefined && this.props.onChange != null ? {onChange:this.props.onChange(this.props.field)} : {};
    return (
      <TextField
        className='ard-TextFormField'
        label={this.props.field.createData.Title}
        name={this.props.field.internalName}
        defaultValue={this.props.value}
        // onChange={this.props.onChange(this.props.field)}
        // placeholder={strings.TextFormFieldPlaceholder}
        multiline={this.props.field.createData.TypeAsString === 'Note'}
        noValidate
        required={this.props.field.createData.Required}
        description={this.props.field.createData.Description}
        readOnly={this.props.readOnly != undefined ? this.props.readOnly : false}
        key={this.props.field.createData.Title}
        {...onChangeParam}
      />
    );
  }
}

export default SPFieldText;