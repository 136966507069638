import * as React from "react";
import styles from "./styles/CreateForm.scss";
import Store from "../../store/store";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ICreateFormProps } from "./interfaces/ICreateFormProps";
import { ICreateFormState } from "./interfaces/ICreateFormState";
import { ControlMode } from "../renderForm/interfaces/ControlMode";
import { Panel } from 'office-ui-fabric-react/lib/Panel';
import { useQuery, constructTree } from "./../../helper/helpers";

import RenderForm from "../renderForm/RenderForm";
import { TextField, MaskedTextField } from "office-ui-fabric-react/lib/components/TextField";
import { ChoiceGroup, IChoiceGroupOption } from 'office-ui-fabric-react/lib/ChoiceGroup';
import { Dropdown, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { DefaultButton, PrimaryButton, ActionButton } from 'office-ui-fabric-react/lib/Button';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import axios from "axios";
import { IFormInfo, IColumnInfo } from "../renderForm/interfaces/IRenderForm";
import { Label } from 'office-ui-fabric-react/lib/Label';
import { DatePicker, DayOfWeek, IDatePickerStrings } from 'office-ui-fabric-react/lib/DatePicker';
import { DayPickerStrings, onFormatDate, hoursMinutesParams, formatHours } from "../renderForm/formFields/SPFieldDateTime";
import { Dialog, DialogFooter, DialogType } from "office-ui-fabric-react/lib/Dialog";
import { ITypeForm, IDBDocument } from "../../Workflow/interfaces/IWorkflow";
import TreeMenu from "react-simple-tree-menu";
import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react/lib/DetailsList';


class CreateForm extends React.Component<ICreateFormProps & RouteComponentProps, ICreateFormState> {
  constructor(props: ICreateFormProps & RouteComponentProps) {
    super(props);
    this.state = {
      formInfo: {
        name: "Titre du formulaire",
        description: "",
        status: "brouillon",
        icon: "bus",
        formType: "",
        displayed: false,
        isCreateFDP: false
      },
      controlMode: ControlMode.Edit,
      fieldsInfo: [],
      panelInfo: {
        isOpen: false,
        mode: "add"
      },
      selectedQuestion: null,
      addFieldData: {
        TypeAsString: "Text",
        Title: "",
        Description: "",
        Required: false,
        MaxLength: 255
      },
      listOfTypeFieldDefaultData: [
        {
          TypeAsString: "Text",
          Title: "",
          Description: "",
          Required: false,
          MaxLength: 255
        },
        {
          TypeAsString: "Note",
          Title: "",
          Description: "",
          Required: false
        },
        {
          TypeAsString: "Number",
          Title: "",
          Description: "",
          Required: false,
          MinimumValue: null,
          MaximumValue: null
        },
        {
          TypeAsString: "Choice",
          Title: "",
          Description: "",
          Required: false,
          Choices: ["Choix 1", "Choix 2", "Choix 3"],
          EditFormat: 0,
          isMulti: false
        },
        {
          TypeAsString: "DateTime",
          Title: "",
          Description: "",
          Required: false,
          DisplayFormat: 0,
          DefaultValue: null,
          DropDownChoiceDate: "None"
        },
        {
          TypeAsString: "Boolean",
          Title: "",
          Description: "",
          Required: false,
          DefaultValue: "1",
        },
        {
          TypeAsString: "Title",
          Title: "",
        },
        {
          TypeAsString: "Paragraph",
          Description: "",
        },
        {
          TypeAsString: "File",
          Title: "",
          Required: false,
        },
      ],
      loading: {
        form: false,
        addColumn: false,
        infoText: false,
        deleteAnswer: false,
        param: true
      },
      deleteDialogParams: {
        hideDialog: true,
        type: "question",
        cantDelete: false,
        wfLists: [],
        loading: false,
        error: false
      },
      openParamArea: true,
      typeFormList: [],
      error: false,
      errorMessage: "",

      treeModeles: [],
      publishModalLoadingParams: {
        loading: true,
        publishButton: false,
        error: false,
        errorMessage: "",
        hidePublishDialog: true,
        selectedModel: null
      },
      modelCircuitParamsDialog: {
        loading: true,
        saveButton: false,
        error: false,
        errorMessage: "",
        hideCircuitDialog: true,
        selectedModel: null
      },
      duplicateDialog: {
        loading: false,
        error: false,
        errorMessage: "",
        hideDuplicateDialog: true,
      },
    };
  }

  public componentDidUpdate(prevProps: ICreateFormProps & RouteComponentProps, prevState: ICreateFormState) {
    if (this.props.location.search != prevProps.location.search) {
      // Récupération de l'id du form dans l'url
      const query = useQuery();
      const idForm: number = parseInt(query.get('id'));

      let errorMessage = "";
      // Si il y a un id on récupére le form
      if (!isNaN(idForm)) {
        this.setState({ loading: { ...this.state.loading, form: true, param: true } }, async () => {
          try {
            // Récupération du formulaire
            errorMessage = "Une erreur est survenue lors de la récupération du formulaire";
            const form: IFormInfo = await this.getFormById(idForm);

            if (form != undefined && form != null && form.id != undefined) {
              this.setState({ formInfo: form, fieldsInfo: form.form_Columns, loading: { ...this.state.loading, form: false, param: false }, openParamArea: true }, () => {
                if (this.state.formInfo.status == "brouillon") {
                  const publishParam: string = query.get('publish');
                  if (publishParam == "true") {
                    this.openPublishModal();
                  }
                }
              });
            }
            else {
              errorMessage = "Le formulaire avec l'id : " + idForm + " n'a pas été trouvé";
              Store.snackBar = { error: true, message: errorMessage, open: true };
              this.setState({ loading: { ...this.state.loading, form: false }, error: true, errorMessage: "Le formulaire avec l'id : " + idForm + " n'a pas été trouvé" });
            }
          } catch (error) {
            console.error("Error 'getForm did update' : ", error);
            Store.snackBar = { error: true, message: errorMessage, open: true };
            this.setState({ loading: { ...this.state.loading, form: false }, error: true, errorMessage: errorMessage });
          }
        });
      }
    }
  }

  public async componentDidMount() {
    let errorMessage = "Une erreur est survenue lors de la récupération du formulaire";
    try {
      errorMessage = "Une erreur est survenue lors de la récupération des types de formulaires";
      // Récupération des forms type en base
      const allFormTypes: ITypeForm[] = await this.getFormType();

      // Récupération de l'id du form dans l'url
      const query = useQuery();
      const idForm: number = parseInt(query.get('id'));

      // Si il y a un id on récupére le form
      if (!isNaN(idForm)) {
        // Récupération du formulaire
        errorMessage = "Une erreur est survenue lors de la récupération du formulaire";
        const form: IFormInfo = await this.getFormById(idForm);

        if (form != undefined && form != null && form.id != undefined) {
          this.setState({ formInfo: form, fieldsInfo: form.form_Columns, loading: { ...this.state.loading, form: false, param: false }, openParamArea: false, typeFormList: allFormTypes }, () => {
            if (this.state.formInfo.status == "brouillon") {
              const publishParam: string = query.get('publish');
              if (publishParam == "true") {
                this.openPublishModal();
              }
            }
          });
        }
        else {
          errorMessage = "Le formulaire avec l'id : " + idForm + " n'a pas été trouvé";
          Store.snackBar = { error: true, message: errorMessage, open: true };
          this.setState({ loading: { ...this.state.loading, form: false }, typeFormList: allFormTypes, error: true, errorMessage: "Le formulaire avec l'id : " + idForm + " n'a pas été trouvé" });
        }
      }
      else {
        const formInfo: IFormInfo = { ...this.state.formInfo };
        // if (allFormTypes.length == 1) {
        //   formInfo.formType = allFormTypes[0].name;
        // }
        this.setState({ loading: { ...this.state.loading, form: false, param: false }, formInfo: formInfo, typeFormList: allFormTypes });
      }
    } catch (error) {
      console.error("Error 'getForm' : ", error);
      Store.snackBar = { error: true, message: errorMessage, open: true };
      this.setState({ loading: { ...this.state.loading, form: false }, error: true, errorMessage: errorMessage });
    }
  }

  // Fonction pour récupérer tous les types de formulaires en base de données
  public getFormType = () => {
    return new Promise<ITypeForm[]>(async (resolve, reject) => {
      try {
        const result = await axios.get(Store.wsPath + "/1/formulaire/GetAllFormType");
        const allTypeForm: ITypeForm[] = result.data;
        resolve(allTypeForm);
      } catch (error) {
        console.error("Error 'getFormType' : ", error);
        reject("Error 'getFormType' : " + error);
      }
    });
  }

  // Fonction pour récupérer un formulaire en base de données
  public getFormById = async (id: number) => {
    return new Promise<IFormInfo>(async (resolve, reject) => {
      try {
        const result = await axios.get(Store.wsPath + "/1/formulaire/GetFormById", {
          params: {
            idForm: id
          }
        });
        const form: IFormInfo = result.data;
        resolve(form);
      } catch (error) {
        console.error("Error 'getFormById' : ", error);
        reject("Error 'getFormById' : " + error);
      }
    });
  }

  public onChangeFormInfo = (type: string) => (event: any, newValue?: any) => {
    const formInfo = this.state.formInfo;
    if (type == "title") {
      formInfo.name = event.target.value;
    }
    else if (type == "description") {
      formInfo.description = event.target.value;
    }
    else if (type == "icone") {
      event.target.value != undefined && event.target.value != null && event.target.value.length > 0 ? formInfo.icon = event.target.value.toLowerCase() : formInfo.icon = "";
    }
    else if (type == "formType") {
      formInfo.formType = newValue.key;
    }
    else if (type == "displayed") {
      formInfo.displayed = newValue;
    }

    this.setState({ formInfo: formInfo });
  }

  // Fonction pour créer les questions obligatoire d'un type de formulaire
  public createFormTypeQuestion = async (idForm: number, newForm: ITypeForm, oldForm?: ITypeForm) => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const result = await axios.post(Store.wsPath + "/1/formulaire/CreateFormTypeQuestion", {
          idForm: idForm,
          newType: newForm,
          oldType: oldForm
        });
        const form: any = result.data;
        resolve(form);
      } catch (error) {
        console.error("Error 'CreateFormTypeQuestion' : ", error);
        reject("Error 'CreateFormTypeQuestion' : " + error);
      }
    });
  }

  public onChangeFormType = (event: any, newValue?: any) => {
    const formInfo = { ...this.state.formInfo };
    const oldFormType: string = this.state.formInfo.formType;
    if (newValue.key != formInfo.formType) {
      formInfo.formType = newValue.key;
      this.setState({ formInfo: formInfo, loading: { ...this.state.loading, form: true } }, async () => {
        let errorMessage: string = "";
        try {
          const newType = this.state.typeFormList.filter(type => type.name == newValue.key)[0];
          if (formInfo != undefined && formInfo != null && formInfo.id != undefined && formInfo.id != undefined) {
            // Le formulaire existe déjà mise à jour des colonnes systéme
            const oldType = this.state.typeFormList.filter(type => type.name == oldFormType)[0];
            await this.createFormTypeQuestion(formInfo.id, newType, oldType);

            const form: IFormInfo = await this.getFormById(formInfo.id);
            if(newType.params.defaultIcon != undefined && newType.params.defaultIcon != null){
              form.icon= newType.params.defaultIcon
            }
            this.setState({ formInfo: form, fieldsInfo: form.form_Columns, loading: { ...this.state.loading, form: false }, openParamArea: true});
          }
          else {
            errorMessage = "Erreur lors de la création du formulaire";
            //Le formulaire n'existe pas du coup on le créé et après on mets à jour les colonnes
            const formCreated = await this.createUpdateFormFunction(formInfo, true);

            await this.createFormTypeQuestion(formCreated.id, newType);

            const form: IFormInfo = await this.getFormById(formCreated.id);
            if(newType.params.defaultIcon != undefined && newType.params.defaultIcon != null){
              form.icon= newType.params.defaultIcon
            }
            this.setState({ formInfo: form, fieldsInfo: form.form_Columns, loading: { ...this.state.loading, form: false }, openParamArea: true });

            const query = useQuery();
            query.append("id", formCreated.id.toString());
            window.history.replaceState(null, null, '/createForm?' + query);
          }
        } catch (error) {
          console.error("Error 'onChangeFormType' : ", errorMessage, " ", error);
          this.setState({ error: true, errorMessage: errorMessage });
        }
      });
    }
  }

  // Fonction pour créer les questions obligatoire pour une fiche de poste
  public createFDPQuestion = async (formInfo: IFormInfo) => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await axios.post(Store.wsPath + "/1/formulaire/CreateOrRemoveFDPQuestion", {
          formInfo: formInfo
        });
        resolve();
      } catch (error) {
        console.error("Error 'createFDPQuestion' : ", error);
        reject("Error 'createFDPQuestion' : " + error);
      }
    });
  }

  // Fonction appelée lors du check de l'option fiche de poste
  public onChangeFicheDePosteForm = (event: any, newValue: boolean) => {
    const formInfo = { ...this.state.formInfo };
    formInfo.isCreateFDP = newValue;
    this.setState({ formInfo: formInfo, loading: { ...this.state.loading, form: true } }, async () => {
      let errorMessage: string = "";
      try {
        // Le formulaire existe déjà mise à jour des colonnes de fiche de poste
        errorMessage = "Erreur lors de l'ajout des questions pour la fiche de poste";
        await this.createFDPQuestion(formInfo);

        const form: IFormInfo = await this.getFormById(formInfo.id);
        this.setState({ formInfo: form, fieldsInfo: form.form_Columns, loading: { ...this.state.loading, form: false }, openParamArea: true });
      } catch (error) {
        console.error("Error 'onChangeDemandeDeForm' : ", errorMessage, " ", error);
        this.setState({ error: true, errorMessage: errorMessage });
      }
    });
  }

  public openPanel = (mode: "add" | "edit", question?: IColumnInfo) => (event: any) => {
    if (mode == "edit") {
      this.setState({ panelInfo: { isOpen: true, mode: mode }, selectedQuestion: { ...question }, addFieldData: { ...question.createData } });
    }
    else {
      this.setState({ panelInfo: { isOpen: true, mode: mode }, selectedQuestion: null, addFieldData: { ...this.state.listOfTypeFieldDefaultData.filter(list => list.TypeAsString == "Text")[0] } });
    }
  }

  public closePanel = () => {
    const allListType = [...this.state.listOfTypeFieldDefaultData];
    const defaultColumnValue = allListType.filter(list => list.TypeAsString == "Text")[0];
    this.setState({ panelInfo: { isOpen: false, mode: "add" }, addFieldData: defaultColumnValue, loading: { ...this.state.loading, addColumn: false }, selectedQuestion: null });
  }

  public onChangeColumn = (event, option: IDropdownOption) => {
    const columnInfo = { ...this.state.addFieldData };
    const findTypeData = this.state.listOfTypeFieldDefaultData.filter(Question => Question.TypeAsString == option.key);
    if (findTypeData.length > 0) {
      const newValue = findTypeData[0];
      newValue["Title"] = columnInfo["Title"];
      newValue["Description"] = columnInfo["Description"];
      newValue["Required"] = columnInfo["Required"];
      this.setState({ addFieldData: newValue });
    }
    else {
      columnInfo["TypeAsString"] = option.key;
      this.setState({ addFieldData: columnInfo });
    }
  }

  public onChange = (key: string, type?: string) => (event, newValue: any) => {
    const columnInfo = { ...this.state.addFieldData };
    switch (key) {
      case "MaxLength":
        columnInfo[key] = parseInt(newValue);
        break;

      case "MaximumValue":
      case "MinimumValue":
        columnInfo[key] = newValue != "" ? parseInt(newValue) : null;
        break;

      case "EditFormat":
        columnInfo[key] = parseInt(newValue.key);
        break;

      case "DefaultValue":
        if (type == "Choice") {
          let choiceNewValue = newValue.key;
          if (choiceNewValue == "None") {
            choiceNewValue = null;
          }
          columnInfo[key] = choiceNewValue;
        }
        else if (type == "Number") {
          columnInfo[key] = newValue != "" ? parseInt(newValue) : null;
        }
        else if (type == "Boolean") {
          columnInfo["DefaultValue"] = newValue.key;
        }
        else {
          columnInfo[key] = newValue.length == 0 ? null : newValue;
        }
        break;

      case "isMulti":
        columnInfo["DefaultValue"] = null;
        columnInfo["EditFormat"] = 0;
        columnInfo[key] = newValue;
        break;

      case "DisplayFormat":
        columnInfo["DisplayFormat"] = newValue == true ? 1 : 0;
        break;

      case "DropDownChoiceDate":
        columnInfo[key] = newValue.key;
        if (newValue.key == "None") {
          columnInfo["DefaultValue"] = null;
        }
        else if (newValue.key == "[today]") {
          columnInfo["DefaultValue"] = newValue.key;
        }
        else {
          columnInfo["DefaultValue"] = null;
        }
        break;


      default:
        columnInfo[key] = newValue;
        break;
    }

    this.setState({ addFieldData: columnInfo });
  }

  public onChangeDate = (key: string, type?: string) => (event: Date) => {
    const dateString: string = event.toString();
    const dateWithoutUTC = dateString.split(" GMT")[0];

    const columnInfo = { ...this.state.addFieldData };

    columnInfo[key] = dateWithoutUTC;

    this.setState({ addFieldData: columnInfo });
  }

  public onChangeHour = (key: string) => (event: any, newValue: string) => {
    if (newValue.length == 5) {
      const splitHM = newValue.split(":");
      const heures = splitHM[0];
      const minutes = splitHM[1];
      const columnInfo = this.state.addFieldData;
      const dateValue = columnInfo["DefaultValue"];
      const date = new Date(dateValue);
      date.setHours(parseInt(heures), parseInt(minutes), 0);

      const dateWithoutUTC = date.toString().split(" GMT")[0];
      columnInfo[key] = dateWithoutUTC;
      this.setState({ addFieldData: columnInfo });
    }
  }

  // Fonction pour créer ou mettre à jour un form
  public createUpdateFormFunction = async (form: IFormInfo, create: boolean) => {
    return new Promise<IFormInfo>(async (resolve, reject) => {
      try {
        if ((form.id == undefined || form.id == null) && create == true) {
          const result = await axios.post(Store.wsPath + "/1/formulaire/CreateForm", form);
          const formCreated: IFormInfo = result.data;
          resolve(formCreated);
        }
        else {
          const result = await axios.post(Store.wsPath + "/1/formulaire/UpdateFormById", form);
          const formCreated: IFormInfo = result.data;
          resolve(formCreated);
        }
      } catch (error) {
        console.error("Error 'createUpdateFormFunction' : ", error);
        reject("Error 'createUpdateFormFunction' : " + error);
      }
    });
  }

  // Fonction pour créer une colonne
  public createColumnFunction = (formInfo: IFormInfo, columnData: any, order: number) => {
    return new Promise<IColumnInfo>(async (resolve, reject) => {
      try {
        const result = await axios.post(Store.wsPath + "/1/formulaire/CreateAnswer", { createData: columnData, form: formInfo, order: order });
        const columnCreated: IColumnInfo = result.data;
        resolve(columnCreated);
      } catch (error) {
        console.error("Error 'createColumnFunction' : ", error);
        reject("Error 'createColumnFunction' : " + error);
      }
    });
  }

  public addColumn = (event) => {
    event.preventDefault();
    this.setState({ loading: { ...this.state.loading, addColumn: true } }, async () => {
      try {
        // On check si le formulaire est créé ou non si il est pas créé on le créé
        // if (this.state.formInfo == undefined || this.state.formInfo.id == undefined || this.state.formInfo.id == null) {
        //   const formCreated = await this.createUpdateFormFunction(this.state.formInfo, true);

        //   // Ajouter la question
        //   const colonneCreated: any = await this.createColumnFunction(formCreated, this.state.addFieldData, this.state.fieldsInfo.length);
        //   const allColumn: IColumnInfo[] = this.state.fieldsInfo;
        //   allColumn.push(colonneCreated);

        //   this.setState({ formInfo: formCreated, fieldsInfo: allColumn, loading: { ...this.state.loading, addColumn: false } });
        //   const query = useQuery();
        //   query.append("id", formCreated.id.toString());
        //   window.history.replaceState(null, null, '/createForm?' + query);
        // }
        // else {
        const colonneCreated: any = await this.createColumnFunction(this.state.formInfo, this.state.addFieldData, this.state.fieldsInfo.length);
        const allColumn: IColumnInfo[] = this.state.fieldsInfo;
        allColumn.push(colonneCreated);
        this.setState({ fieldsInfo: allColumn, loading: { ...this.state.loading, addColumn: false } });
        // }
        this.closePanel();
      } catch (error) {
        console.error("Error 'addColumn' : ", error);
        this.setState({ loading: { ...this.state.loading, addColumn: false } });
        Store.snackBar = {
          error: true,
          message: "Une erreur est survenue lors de l'ajout de la colonne",
          open: true
        };
      }
    });
  }

  // Fonction pour mettre à jour une question
  public editQuestionFunction = (columnData: IColumnInfo, formInfo:IFormInfo, formType:ITypeForm) => {
    return new Promise<IColumnInfo>(async (resolve, reject) => {
      try {
        const result = await axios.post(Store.wsPath + "/1/formulaire/UpdateQuestionById", { columnData: columnData, formInfo: formInfo, formType: formType });
        const columnUpdated: IColumnInfo = result.data;
        resolve(columnUpdated);
      } catch (error) {
        console.error("Error 'editQuestionFunction' : ", error);
        reject("Error 'editQuestionFunction' : " + error);
      }
    });
  }

  public editQuestion = (event) => {
    event.preventDefault();
    this.setState({ loading: { ...this.state.loading, addColumn: true } }, async () => {
      try {
        const selectedQuestion: IColumnInfo = this.state.selectedQuestion;
        const modifData = this.state.addFieldData;

        selectedQuestion["createData"] = { ...modifData };

        const questionUpdated: IColumnInfo = await this.editQuestionFunction(selectedQuestion, this.state.formInfo, this.state.typeFormList.filter(type => type.name == this.state.formInfo.formType)[0]);

        const allQuestions: IColumnInfo[] = [...this.state.fieldsInfo];

        const indexFind = allQuestions.findIndex(question => question.id == questionUpdated.id);
        allQuestions[indexFind] = questionUpdated;

        this.setState({ fieldsInfo: allQuestions }, () => {
          this.closePanel();
          Store.snackBar = {
            error: false,
            message: "Question mise à jour",
            open: true
          };
        });
      } catch (error) {
        console.error("Error 'editQuestion' : ", error);
        this.setState({ loading: { ...this.state.loading, addColumn: false } });
        Store.snackBar = {
          error: true,
          message: "Une erreur est survenue lors de la mise à jour",
          open: true
        };
      }
    });
  }

  // a little function to help us with reordering the result
  public reorder = (list, startIndex, endIndex) => {
    const result: IColumnInfo[] = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  // Fonction appelée lors du changement d'ordre des questions
  public moveQuestion = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    else {
      const items = this.reorder(
        this.state.fieldsInfo,
        result.source.index,
        result.destination.index
      );

      this.setState({
        fieldsInfo: items,
        loading: { ...this.state.loading, infoText: true }
      }, () => {
        this.saveOrder(this.state.fieldsInfo).then(result => {
          this.setState({ loading: { ...this.state.loading, infoText: false } });
        }).catch(error => {
          this.setState({ loading: { ...this.state.loading, infoText: false } });
        });
      });
    }
  }

  // Fonction appelée pour mettre à jour l'ordre des colonnes en bdd
  public saveOrder = (allFields: IColumnInfo[]) => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await axios.post(Store.wsPath + "/1/formulaire/ChangeOrder", { allColumnsData: allFields });
        resolve();
      } catch (error) {
        console.error("Error 'saveOrder' : ", error);
        reject();
      }
    });
  }

  // Fonction appelée lors de la suppression d'une question pour ouvrir la modale
  public deleteQuestionOrFormModal = (type: string, question?: IColumnInfo) => (event: any) => {
    this.setState({ deleteDialogParams: { ...this.state.deleteDialogParams, hideDialog: false, type: type }, selectedQuestion: type == "question" ? { ...question } : null });
  }

  // Fonction appelée lors de la suppression d'une question
  public deleteQuestion = (question: IColumnInfo, form: IFormInfo) => (event: any) => {
    this.setState({ loading: { ...this.state.loading, deleteAnswer: true } }, async () => {
      try {
        await axios.post(Store.wsPath + "/1/formulaire/DeleteAnswer", { answer: question, form: form });
        Store.snackBar = {
          message: "Question supprimée avec succèes",
          error: false,
          open: true
        };
        const allQuestions = [...this.state.fieldsInfo];
        const questionRemoved = allQuestions.filter(quest => quest.id != question.id);
        this.setState({ loading: { ...this.state.loading, deleteAnswer: false }, fieldsInfo: questionRemoved }, this.closeDeleteDialog);
      } catch (error) {
        console.error("Error 'deleteQuestion' : ", error);
        this.setState({ loading: { ...this.state.loading, deleteAnswer: false } });
        Store.snackBar = {
          message: "Une erreur est survenue lors de la suppression de la question",
          error: true,
          open: true
        };
      }
    });
  }

  // Fonction appelée lors de la suppression du formulaire
  public deleteForm = (form: IFormInfo) => (event: any) => {
    this.setState({ deleteDialogParams: { ...this.state.deleteDialogParams, loading: true } }, async () => {
      try {
        const resultDelete = await axios.post(Store.wsPath + "/1/formulaire/DeleteFormById", { idForm: form.id });
        if (resultDelete.data.cantDelete != undefined && resultDelete.data.cantDelete != null && resultDelete.data.cantDelete == true) {
          this.setState({ deleteDialogParams: { ...this.state.deleteDialogParams, cantDelete: true, wfLists: resultDelete.data.wf, loading: false } });
        }
        else {
          Store.snackBar = {
            message: "Formulaire supprimé avec succès",
            error: false,
            open: true
          };
          this.props.history.push("/forms");
        }
      } catch (error) {
        console.error("Error 'deleteForm' : ", error);
        this.setState({ deleteDialogParams: { ...this.state.deleteDialogParams, loading: false, error: true } });
        Store.snackBar = {
          message: "Une erreur est survenue lors de la suppression du formulaire",
          error: true,
          open: true
        };
      }
    });
  }


  public updateFormData = async (event: any) => {
    if (this.state.formInfo.id != undefined && this.state.formInfo.id != null) {
      try {
        this.setState({
          loading: { ...this.state.loading, infoText: true }
        }, async () => {
          const resultForm = await this.createUpdateFormFunction(this.state.formInfo, false);
          this.setState({
            loading: { ...this.state.loading, infoText: false }
          });
        });
      } catch (error) {
        console.error("Error 'updateFormData' : ", error);
        this.setState({ loading: { ...this.state.loading, infoText: false } });
        Store.snackBar = {
          message: "Une erreur est survenue lors de la mise à jour du formulaire",
          error: true,
          open: true
        };
      }
    }
  }

  // Fonction pour ajouter un choix dans la liste de choix
  public addChoiceInChoicesList = (event: any) => {
    const choices: string[] = [...this.state.addFieldData.Choices];
    if (choices != undefined && choices != null) {
      choices.push("Choix " + (choices.length + 1));
      this.setState({
        addFieldData: { ...this.state.addFieldData, Choices: choices }
      });
    }
  }

  // Fonction pour supprimer un choix dans la liste de choix
  public removeChoiceInChoicesList = (index: number) => (event: any) => {
    const choices: string[] = [...this.state.addFieldData.Choices];
    if (choices != undefined && choices != null) {
      choices.splice(index, 1);
      this.setState({
        addFieldData: { ...this.state.addFieldData, Choices: choices }
      });
    }
  }

  // Fonction pour supprimer un choix dans la liste de choix
  public renameChoiceInChoicesList = (index: number) => (event: any) => {
    const newValue = event.target.value;
    if (newValue.length > 0) {
      const choices: string[] = [...this.state.addFieldData.Choices];
      if (choices != undefined && choices != null) {
        choices[index] = newValue;
        this.setState({
          addFieldData: { ...this.state.addFieldData, Choices: choices }
        });
      }
    }
    else {
      // Si la valeur est vide on enlève le choix du tableau
      const choices: string[] = [...this.state.addFieldData.Choices];
      if (choices != undefined && choices != null) {
        choices.splice(index, 1);
        this.setState({
          addFieldData: { ...this.state.addFieldData, Choices: choices }
        });
      }
    }
  }

  public fieldsQuestions = (type: string) => {
    switch (type) {
      case "Text":
        return (
          <React.Fragment>
            <TextField
              onChange={this.onChange("MaxLength")}
              type="number"
              label="Nombre maximal de caractères"
              min={1}
              key="Text/MaxLength"
              defaultValue={this.state.addFieldData.MaxLength != undefined && this.state.addFieldData.MaxLength != null ? this.state.addFieldData.MaxLength : "255"}
              // disabled={this.state.panelInfo.mode != "add" && this.state.formInfo.status != "brouillon" ? true : false}
            />
            <TextField
              // disabled={this.state.panelInfo.mode != "add" && this.state.formInfo.status != "brouillon" ? true : false}
              onChange={this.onChange("DefaultValue")}
              label="Valeur par défaut"
              defaultValue={this.state.addFieldData.DefaultValue != undefined && this.state.addFieldData.DefaultValue != null ? this.state.addFieldData.DefaultValue : ""}
            />
          </React.Fragment>
        );
      case "Note":
        return (
          <TextField
            // disabled={this.state.panelInfo.mode != "add" && this.state.formInfo.status != "brouillon" ? true : false}
            onChange={this.onChange("DefaultValue")}
            label="Valeur par défaut"
            key="Note/DefaultValue"
            defaultValue={this.state.addFieldData.DefaultValue != undefined && this.state.addFieldData.DefaultValue != null ? this.state.addFieldData.DefaultValue : ""}
          />
        );
      case "Number":
        return (
          <React.Fragment>
            <TextField
              // disabled={this.state.panelInfo.mode != "add" && this.state.formInfo.status != "brouillon" ? true : false}
              onChange={this.onChange("MinimumValue")}
              type="number"
              label="Valeur minimale autorisée"
              key="number/MinimumValue"
              defaultValue={this.state.addFieldData.MinimumValue != undefined && this.state.addFieldData.MinimumValue != null ? this.state.addFieldData.MinimumValue.toString() : ""}
            />
            <TextField
              // disabled={this.state.panelInfo.mode != "add" && this.state.formInfo.status != "brouillon" ? true : false}
              onChange={this.onChange("MaximumValue")}
              type="number"
              label="Valeur maximale autorisée"
              key="number/MaximumValue"
              defaultValue={this.state.addFieldData.MaximumValue != undefined && this.state.addFieldData.MaximumValue != null ? this.state.addFieldData.MaximumValue.toString() : ""}
            />
            <TextField
              // disabled={this.state.panelInfo.mode != "add" && this.state.formInfo.status != "brouillon" ? true : false}
              onChange={this.onChange("DefaultValue", "Number")}
              type="number"
              label="Valeur par défaut"
              key="number/DefaultValue"
              defaultValue={this.state.addFieldData.DefaultValue != undefined && this.state.addFieldData.DefaultValue != null ? this.state.addFieldData.DefaultValue.toString() : ""}
            />
          </React.Fragment>
        );
      case "Choice":
        const optionDefaultValue = this.state.addFieldData.Choices != undefined && this.state.addFieldData.Choices.length > 0 ? this.state.addFieldData.Choices.map(choice => { return { key: choice, text: choice } }) : [];
        optionDefaultValue.unshift({ key: "None", text: "Aucun" });
        return (
          <>
            <div className={styles.choicesListContainer}>
              <Label>Choix</Label>
              <div className={styles.choicesList}>
                {
                  this.state.addFieldData.Choices.map((choice: string, index: number) => (
                    <div key={choice + "/" + index} className={styles.choicesItem}>
                      <TextField /*disabled={this.state.panelInfo.mode != "add" && this.state.formInfo.status != "brouillon" ? true : false}*/ className={styles.choicesItemInput} onBlur={this.renameChoiceInChoicesList(index)} borderless defaultValue={choice} />
                      <Icon onClick={this.removeChoiceInChoicesList(index)} className={styles.choicesItemIcon} iconName="Delete" title="Supprimer" />
                    </div>
                  ))
                }
              </div>
              <ActionButton /* disabled={this.state.panelInfo.mode != "add" && this.state.formInfo.status != "brouillon" ? true : false}*/ onClick={this.addChoiceInChoicesList} iconProps={{ iconName: 'Add' }}>Ajouter un choix</ActionButton>
            </div>
            <Dropdown
              key={"Valeur par défaut" + this.state.addFieldData.isMulti}
              label="Valeur par défaut"
              selectedKey={this.state.addFieldData.DefaultValue != undefined && this.state.addFieldData.DefaultValue != null ? this.state.addFieldData.DefaultValue : "None"}
              selectedKeys={this.state.addFieldData.DefaultValue != undefined && this.state.addFieldData.DefaultValue != null ? this.state.addFieldData.DefaultValue : []}
              onChange={this.onChange("DefaultValue", "Choice")}
              options={optionDefaultValue}
              multiSelect={false}
              // disabled={this.state.panelInfo.mode != "add" && this.state.formInfo.status != "brouillon" ? true : false}
            />
            <ChoiceGroup
              disabled={this.state.addFieldData.isMulti /*|| (this.state.panelInfo.mode != "add" && this.state.formInfo.status != "brouillon")*/}
              selectedKey={this.state.addFieldData.EditFormat != undefined && this.state.addFieldData.EditFormat != null ? this.state.addFieldData.EditFormat.toString() : "0"}
              options={[{ key: "0", text: "Menu déroulant" }, { key: "1", text: "Cases d'option" }]}
              onChange={this.onChange("EditFormat")} label="Afficher les choix via :"
            />
            <Toggle disabled={this.state.panelInfo.mode != "add" && this.state.formInfo.status != "brouillon" ? true : false} defaultChecked={this.state.addFieldData.isMulti == true ? true : false} label="Autoriser les sélections multiples" onText="Oui" offText="Non" onChange={this.onChange("isMulti")} />
          </>
        );
      case "DateTime":
        const optionDefaultValueDateTime = [
          { key: "None", text: "Aucun" },
          { key: "[today]", text: "Date du jour" },
          { key: "Custom", text: "Sélectionner une date et une heure" }
        ];
        return (
          <React.Fragment>
            <Toggle
              defaultChecked={this.state.addFieldData.DisplayFormat == 1 ? true : false}
              label="Inclure l'heure"
              onText="Oui"
              offText="Non"
              onChange={this.onChange("DisplayFormat")}
              // disabled={this.state.panelInfo.mode != "add" && this.state.formInfo.status != "brouillon" ? true : false}
            />
            <Dropdown
              key={"Valeur par défaut/DateTime"}
              label="Valeur par défaut"
              selectedKey={this.state.addFieldData.DropDownChoiceDate != undefined && this.state.addFieldData.DropDownChoiceDate != null ? this.state.addFieldData.DropDownChoiceDate : "None"}
              onChange={this.onChange("DropDownChoiceDate")}
              options={optionDefaultValueDateTime}
              // disabled={this.state.panelInfo.mode != "add" && this.state.formInfo.status != "brouillon" ? true : false}
            />
            {
              this.state.addFieldData.DropDownChoiceDate == "Custom" ?
                <React.Fragment>
                  <DatePicker
                    firstDayOfWeek={DayOfWeek.Monday}
                    strings={DayPickerStrings}
                    placeholder="Séléctionner une date..."
                    ariaLabel="Séléctionner une date..."
                    style={{ marginTop: "5px" }}
                    formatDate={onFormatDate}
                    onSelectDate={this.onChangeDate("DefaultValue")}
                    key="datePickerInput"
                    isRequired={true}
                    value={this.state.addFieldData.DefaultValue != null && this.state.addFieldData.DefaultValue != "[today]" ? new Date(this.state.addFieldData.DefaultValue) : null}
                    // disabled={this.state.panelInfo.mode != "add" && this.state.formInfo.status != "brouillon" ? true : false}
                  />
                  {
                    this.state.addFieldData.DisplayFormat == 1 ?
                      <TextField
                        type="time"
                        key={"TextTimeField"}
                        label="Heures et Minutes (hh:mm)"
                        onChange={this.onChangeHour("DefaultValue")}
                        required={true}
                        value={this.state.addFieldData.DefaultValue != null && this.state.addFieldData.DefaultValue != "[today]" ? formatHours(this.state.addFieldData.DefaultValue) : "00:00"}
                        disabled={this.state.addFieldData.DefaultValue != null && this.state.addFieldData.DefaultValue != "[today]" /*&& (this.state.panelInfo.mode == "add" && this.state.formInfo.status == "brouillon")*/ ? false : true}
                        pattern="^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"
                        title="Respecter le format : hh:mm"
                      />
                      :
                      ""
                  }
                </React.Fragment>
                :
                ""
            }
          </React.Fragment>
        );

      case "Boolean":
        return (
          <Dropdown
            key={"Valeur par défaut/Boolean"}
            label="Valeur par défaut"
            selectedKey={this.state.addFieldData.DefaultValue != undefined && this.state.addFieldData.DefaultValue != null ? this.state.addFieldData.DefaultValue : "0"}
            onChange={this.onChange("DefaultValue", "Boolean")}
            options={[{ key: "1", text: "Oui" }, { key: "0", text: "Non" }]}
            // disabled={this.state.panelInfo.mode != "add" && this.state.formInfo.status != "brouillon" ? true : false}
          />
        );

      case "File":
        return (
          <p style={{ margin: "5px 0" }}>Formats acceptés : .jpg, .jpeg, .png, .pdf</p>
        );

      default:
        break;
    }
  }

  public closeDeleteDialog = () => {
    this.setState({ deleteDialogParams: { hideDialog: true, type: "question", wfLists: [], cantDelete: false, loading: false, error: false }, selectedQuestion: null });
  }

  public openConfirmDeleteDialog = () => {
    this.setState({ deleteDialogParams: { hideDialog: false, type: "confirmForm", wfLists: [], cantDelete: false, loading: false, error: false }, selectedQuestion: null });
  }

  public closePublishDialog = () => {
    const query = useQuery();
    const publishParam: string = query.get('publish');
    if (publishParam == "true") {
      this.props.history.push("/forms");
    }
    else {
      this.setState({ publishModalLoadingParams: { ...this.state.publishModalLoadingParams, hidePublishDialog: true } });
    }
  }

  public publish = () => {
    this.setState({ publishModalLoadingParams: { ...this.state.publishModalLoadingParams, publishButton: true } }, async () => {
      try {
        const publishInfo = {
          name: this.state.formInfo.name,
          description: this.state.formInfo.description,
          icon: this.state.formInfo.icon,
          formType: this.state.formInfo.formType,
          id: this.state.formInfo.id,
          model: this.state.publishModalLoadingParams.selectedModel,
          displayed: this.state.formInfo.displayed,
        }

        await axios.post(Store.wsPath + "/1/formulaire/PublishForm", publishInfo);
        Store.snackBar = {
          message: "Formulaire publié",
          error: false,
          open: true
        };
        this.props.history.push("/forms");
      } catch (error) {
        console.error("Error 'publish' : ", error);
        Store.snackBar = {
          message: "Une erreur est survenue lors de la publication du formulaire",
          error: true,
          open: true
        };
        this.setState({ publishModalLoadingParams: { ...this.state.publishModalLoadingParams, error: true, errorMessage: "Une erreur est survenue lors de la publication du formulaire" } });
      }
    });
  }

  public onClickModele = (typeDialog: string) => (nodes) => {
    if (nodes != undefined && nodes != null) {
      if (typeDialog == "publish") {
        this.setState({ publishModalLoadingParams: { ...this.state.publishModalLoadingParams, selectedModel: { name: nodes.label, guid: nodes.guid, id: nodes.id } } });
      }
      else {
        this.setState({ modelCircuitParamsDialog: { ...this.state.modelCircuitParamsDialog, selectedModel: { name: nodes.label, guid: nodes.guid, id: nodes.id } } });
      }
    }
  }

  // Fonction qui récupére tous les modéles
  public getAllModelsWf = () => {
    return new Promise<{ treeModeles: any[], allModelsList: any[], allTypeForm: ITypeForm[] }>(async (resolve, reject) => {
      try {
        const allModelsWF = await axios.get(Store.wsPath + `/1/workflows/getAllModelWf`);
        const treeModeles = await constructTree(allModelsWF.data, this.state.typeFormList);
        resolve(treeModeles);
        this.setState({ treeModeles: treeModeles.treeModeles });
      } catch (error) {
        console.error("Error 'getAllModelsWf' : ", error);
        reject("Error 'getAllModelsWf' : " + error);
      }
    });
  }

  // Fonction pour ouvrir la modale de publication lors du click sur le bouton publier
  public openPublishModal = () => {
    this.setState({ publishModalLoadingParams: { ...this.state.publishModalLoadingParams, hidePublishDialog: false, loading: true, error: false, errorMessage: "", selectedModel: null, publishButton: false }, formInfo: {...this.state.formInfo, displayed:true} }, async () => {
      try {
        const allModelesInfo = await this.getAllModelsWf();
        this.setState({ treeModeles: allModelesInfo.treeModeles, publishModalLoadingParams: { ...this.state.publishModalLoadingParams, loading: false } }, () => {
          const searchInput = document.getElementsByClassName("rstm-search")[0];
          if (searchInput != null) {
            searchInput.setAttribute('placeholder', "Rechercher un modèle");
          }
        });
      } catch (error) {
        console.error("Error 'openPublishModal' : ", error);
        this.setState({ publishModalLoadingParams: { ...this.state.publishModalLoadingParams, loading: false, error: true, errorMessage: "Une erreur est survenue lors de la récupération de la liste des modèles, réessayez" }, formInfo: {...this.state.formInfo, displayed:false} });
        Store.snackBar = {
          message: "Une erreur est survenue lors de la récupération de la liste des modèles, réessayez",
          error: true,
          open: true
        };
      }
    });
  }

  // Fonction pour ouvrir la modale de selection du modèle de circuit
  public openModeleCircuitModal = () => {
    this.setState({ modelCircuitParamsDialog: { ...this.state.modelCircuitParamsDialog, hideCircuitDialog: false, loading: true, error: false, errorMessage: "", selectedModel: null, saveButton: false } }, async () => {
      try {
        const allModelesInfo = await this.getAllModelsWf();
        this.setState({ treeModeles: allModelesInfo.treeModeles, modelCircuitParamsDialog: { ...this.state.modelCircuitParamsDialog, loading: false } }, () => {
          const searchInput = document.getElementsByClassName("rstm-search")[0];
          if (searchInput != null) {
            searchInput.setAttribute('placeholder', "Rechercher un modèle");
          }
        });
      } catch (error) {
        console.error("Error 'openModeleCircuitModal' : ", error);
        this.setState({ modelCircuitParamsDialog: { ...this.state.modelCircuitParamsDialog, loading: false, error: true, errorMessage: "Une erreur est survenue lors de la récupération de la liste des modèles, réessayez" } });
        Store.snackBar = {
          message: "Une erreur est survenue lors de la récupération de la liste des modèles, réessayez",
          error: true,
          open: true
        };
      }
    });
  }

  // Fonction pour fermer la modale de selection des modèles
  public closeCircuitModeleDialog = () => {
    this.setState({ modelCircuitParamsDialog: { ...this.state.modelCircuitParamsDialog, hideCircuitDialog: true } });
  }

  // Fonction pour fermer la modale de selection des modèles
  public updateFormModel = () => {
    this.setState({ modelCircuitParamsDialog: { ...this.state.modelCircuitParamsDialog, saveButton: true } }, async () => {
      try {
        await axios.post(Store.wsPath + "/1/formulaire/UpdateFormModel", {
          formType: this.state.formInfo.formType,
          id: this.state.formInfo.id,
          model: this.state.modelCircuitParamsDialog.selectedModel
        });

        const formUpdated = await this.getFormById(this.state.formInfo.id);
        this.setState({ modelCircuitParamsDialog: { ...this.state.modelCircuitParamsDialog, saveButton: false, hideCircuitDialog: true }, formInfo: formUpdated });
        Store.snackBar = {
          message: "Formulaire à jour",
          error: false,
          open: true
        };
      } catch (error) {
        console.error("Error 'updateFormModel' : ", error);
        this.setState({ modelCircuitParamsDialog: { ...this.state.modelCircuitParamsDialog, loading: false, error: true, errorMessage: "Une erreur est survenue lors de la modification du modèle sur le formulaire, réessayez" } });
        Store.snackBar = {
          message: "Une erreur est survenue lors de la modification du modèle sur le formulaire, réessayez",
          error: true,
          open: true
        };
      }
    });
  }

  // Fonction pour dupliquer un formulaire
  public duplicateForm = () => {
    this.setState({ duplicateDialog: { ...this.state.duplicateDialog, loading: true, error: false, errorMessage: "" } }, async () => {
      try {
        const duplicateForm = await axios.post(Store.wsPath + "/1/formulaire/DuplicateForm", {
          id: this.state.formInfo.id
        });

        this.openCloseDuplicateFormDialog(false);

        this.props.history.push("/createForm?id=" + duplicateForm.data.id);
      } catch (error) {
        console.error("Error 'duplicateForm' : ", error);
        this.setState({ duplicateDialog: { ...this.state.duplicateDialog, loading: false, error: true, errorMessage: "Une erreur est survenue lors de la duplication du formulaire, réessayez" } });
      }
    });
  }

  // Fonction pour ouvrir la modale de duplication de formulaire
  public openCloseDuplicateFormDialog = (open?: boolean) => {
    if (open == true) {
      this.setState({ duplicateDialog: { ...this.state.duplicateDialog, hideDuplicateDialog: false, loading: false, error: false, errorMessage: "" } });
    }
    else {
      this.setState({ duplicateDialog: { ...this.state.duplicateDialog, hideDuplicateDialog: true, loading: false, error: false, errorMessage: "" } });
    }
  }

  formatDate = (date?: Date | string): string => {
    if(date == undefined ||date == null) return null;
    let dateFormat = typeof date == "string" ? new Date(date) : date;
    const dates = dateFormat.getDate() < 10 ? "0" + dateFormat.getDate() : dateFormat.getDate();
    const month = dateFormat.getMonth()+1 < 10 ? "0" + (dateFormat.getMonth()+1) : (dateFormat.getMonth()+1);
    const year = dateFormat.getFullYear();
  
    return !dateFormat ? '' : dates + '/' + month + "/" + year;
  };
  onSelectDate = (type: string) => (date: Date | null | undefined) => {
    if(type == "dateFermeture"){
      this.setState({
        formInfo : {
          ...this.state.formInfo,
          date_fermeture: date
        }
      }, () => {
        this.updateFormData(null);
      })
    }
  }
  public render() {
    const typeFormListChoice: IDropdownOption[] = this.state.typeFormList != undefined && this.state.typeFormList != null && this.state.typeFormList.length > 0 ? this.state.typeFormList.map(type => { return { key: type.name, text: type.label } }) : [];
    return (
      <div className={"mainContent"}>
        <div className={"titleComponent"} style={{ background: Store.themeParam.backgroundPrimary }}><h5>Création formulaire :</h5></div>
        <div className={"contentComponent " + styles.contentCreateForm}>
          {
            this.state.error == true ?
              <p className={styles.errorMessage}>Une erreur est survenue : {this.state.errorMessage}</p>
              :
              this.state.loading.param == false ?
                <React.Fragment>
                  <div className={styles.paramFormContainer} style={this.state.openParamArea == true ? { background: "#e5e5e5" } : { background: "none" }}>
                    <div className={styles.titleParamForm}>
                      <div>
                        {
                          this.state.loading.infoText == true ?
                            <Spinner size={SpinnerSize.small} label="Mise à jour en cours" labelPosition={"right"} />
                            :
                            <span><Icon iconName="CheckMark" />Formulaire à jour</span>
                        }
                      </div>
                      {/* <h4>Paramétres</h4> */}
                      <div className={styles.titleParamRightSide}>
                        <span
                          title={this.state.formInfo.displayed ? this.state.formInfo.status + " et disponible" : this.state.formInfo.status + " mais non disponible"}
                          className={styles.statusForm}
                          style={this.state.formInfo.displayed ? { color: "green", borderColor: "green" } : { color: "orange", borderColor: "orange" }}
                        >
                          {this.state.formInfo.status}
                        </span>
                        <span className={styles.iconParamForm}><Icon title={this.state.openParamArea == true ? "Fermer paramètres" : "Ouvrir paramètres"} iconName={"Settings"} onClick={() => this.setState({ openParamArea: !this.state.openParamArea })} /></span>
                      </div>
                    </div>
                    <div className={this.state.openParamArea == true ? styles.paramArea : styles.paramArea + " " + styles.paramAreaClose}>
                      <Dropdown
                        label="Type de formulaire"
                        selectedKey={this.state.formInfo.formType != undefined && this.state.formInfo.formType != null && this.state.formInfo.formType.length > 0 ? this.state.formInfo.formType : ""}
                        onChange={this.onChangeFormType}
                        placeholder="Sélectionner un type de formulaire"
                        options={typeFormListChoice}
                        required={true}
                        className={styles.paramAreaField}
                        // onBlur={this.updateFormData}
                        disabled={(this.state.loading.form == true || this.state.formInfo.status != "brouillon") ? true : false}
                      />
                      <div className={styles.iconFormFieldContainer + " " + styles.paramAreaField}>
                        <Label>Icône</Label>
                        <div className={styles.iconFormField}>
                          <i className={this.state.formInfo.icon != undefined && this.state.formInfo.icon != null ? "fa fa-" + this.state.formInfo.icon : "fa fa-question"}></i>
                          <TextField
                            value={this.state.formInfo.icon != undefined && this.state.formInfo.icon != null ? this.state.formInfo.icon : ""}
                            onChange={this.onChangeFormInfo("icone")}
                            borderless
                            placeholder="Nom de l'icône font awesome"
                            onBlur={this.updateFormData}
                            disabled={this.state.loading.form == true ? true : false}
                          />
                        </div>
                      </div>
                      <Toggle defaultChecked={this.state.formInfo.displayed}
                        label={this.state.formInfo.formType != "" && this.state.typeFormList.find(type => type.name == this.state.formInfo.formType) != undefined ? "Disponible dans " + this.state.typeFormList.find(type => type.name == this.state.formInfo.formType).label : "Disponible dans l'outil "}
                        onText="Oui"
                        offText="Non"
                        onChange={this.onChangeFormInfo("displayed")}
                        onBlur={this.updateFormData}
                        className={styles.paramAreaField}
                      />
                      {
                        this.state.formInfo.status == "publié" ?
                          <div className={styles.paramAreaField + " " + styles.paramCircuitContainer}>
                            <Label>Circuit associé</Label>
                            <div className={styles.inputIconContainer}>
                              <TextField
                                borderless
                                placeholder="Pas de modèle associé"
                                readOnly={true}
                                value={this.state.formInfo.WfModel != undefined && this.state.formInfo.WfModel != null ? this.state.formInfo.WfModel.name : ""}
                              />
                              <Icon className={styles.editCircuitModel} title="Modifier le circuit du formulaire" iconName={"Edit"} onClick={this.openModeleCircuitModal} />
                            </div>
                          </div>
                          :
                          <React.Fragment></React.Fragment>
                      }
                      {
                        this.state.formInfo.formType == "lyform" && this.state.typeFormList.length > 0 && this.state.typeFormList.filter(type => type.name == "recruit").length > 0 ?
                          <Toggle defaultChecked={this.state.formInfo.isCreateFDP}
                            label={"Demande de recrutement"}
                            onText="Oui"
                            offText="Non"
                            onChange={this.onChangeFicheDePosteForm}
                            className={styles.paramAreaField}
                            disabled={(this.state.loading.form == true || this.state.formInfo.status != "brouillon") ? true : false}
                          />
                          :
                          <React.Fragment></React.Fragment>
                      }
                      <div className={styles.paramAreaField}>
                        <Label>Date de fermeture</Label>
                        <div className="">
                          <DatePicker
                            value={this.state.formInfo.date_fermeture != null ?  new Date(this.state.formInfo.date_fermeture) : null}
                            formatDate={this.formatDate}
                            onSelectDate={this.onSelectDate("dateFermeture")}
                            borderless
                            firstDayOfWeek={DayOfWeek.Monday}
                            strings={DayPickerStrings}
                            placeholder="Séléctionner une date..."
                            ariaLabel="Séléctionner une date..."
                            disabled={this.state.loading.form == true ? true : false}
                            className=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {
                    this.state.loading.form == false ?
                      this.state.formInfo.id != undefined && this.state.formInfo.id != null ?
                        <React.Fragment>
                          <div className={styles.renderFormContent}>
                            <RenderForm
                              controlMode={this.state.controlMode}
                              formInfo={this.state.formInfo}
                              fieldsInfo={this.state.fieldsInfo}
                              onChangeFormInfo={this.onChangeFormInfo}
                              saveFormInfo={this.updateFormData}
                              openPanel={this.openPanel}
                              moveQuestion={this.moveQuestion}
                              deleteQuestion={this.deleteQuestionOrFormModal}
                              updated={this.state.loading.addColumn}
                              columnSystemStartWith={this.state.typeFormList.find(type => type.name == this.state.formInfo.formType).params.columnStartWith}
                            />
                          </div>
                          <div className={styles.infoTextContainer}>
                            {
                              this.state.loading.infoText == true ?
                                <Spinner size={SpinnerSize.small} label="Mise à jour en cours" labelPosition={"right"} />
                                :
                                <span><Icon iconName="CheckMark" />Formulaire à jour</span>
                            }
                            <div>
                              <DefaultButton
                                text="Supprimer"
                                split
                                splitButtonAriaLabel="Voir plus d'actions"
                                aria-roledescription="split button"
                                menuProps={{
                                  items: [
                                    {
                                      key: 'dupliquer',
                                      text: 'Dupliquer',
                                      iconProps: { iconName: 'DuplicateRow' },
                                      onClick: () => this.openCloseDuplicateFormDialog(true)
                                    }
                                  ]
                                }}
                                onClick={this.deleteQuestionOrFormModal("form")}
                              />
                              {
                                this.state.formInfo.status == "brouillon" ?
                                  <PrimaryButton style={{ marginLeft: "10px" }} text="Publier" onClick={this.openPublishModal} />
                                  :
                                  ""
                              }
                            </div>
                          </div>
                        </React.Fragment>
                        :
                        <React.Fragment></React.Fragment>
                      :
                      <Spinner style={{ margin: "40px 0" }} size={SpinnerSize.small} label="Mise à jour du formulaire cours" labelPosition={"right"} />
                  }
                </React.Fragment>
                :
                <Spinner size={SpinnerSize.large} />
          }

          <Panel
            headerText={this.state.panelInfo.mode == "add" ? "Ajouter une question" : "Modifier la question"}
            // this prop makes the panel non-modal
            isBlocking={false}
            isOpen={this.state.panelInfo.isOpen}
            onDismiss={this.closePanel}
            closeButtonAriaLabel="Close"
            onRenderFooterContent={() => (
              <div className={styles.panelButtonContainer}>
                <PrimaryButton disabled={this.state.loading.addColumn} type="submit" form="columnForm" style={{ marginRight: "10px" }}>
                  {
                    this.state.loading.addColumn ?
                      <Spinner size={SpinnerSize.small} />
                      :
                      <React.Fragment>{this.state.panelInfo.mode == "add" ? "Ajouter la question" : "Modifier la question"}</React.Fragment>
                  }
                </PrimaryButton>
                <DefaultButton onClick={this.closePanel}>Annuler</DefaultButton>
              </div>
            )}
            isFooterAtBottom={true}
            className={styles.formPanel}
          >
            <form id="columnForm" onSubmit={this.state.panelInfo.mode == "add" ? this.addColumn : this.editQuestion} >
              {
                this.state.addFieldData.TypeAsString != "Paragraph" ?
                  <TextField
                    defaultValue={this.state.addFieldData.Title != undefined && this.state.addFieldData.Title != null ? this.state.addFieldData.Title : ""}
                    onChange={this.onChange("Title")}
                    label={this.state.addFieldData.TypeAsString != "Title" ? "Titre de la question :" : "Titre"}
                    required
                  />
                  :
                  ""
              }
              {
                this.state.addFieldData.TypeAsString != "Title" ?
                  <TextField defaultValue={this.state.addFieldData.Description != undefined && this.state.addFieldData.Description != null ? this.state.addFieldData.Description : ""}
                    onChange={this.onChange("Description")}
                    label={this.state.addFieldData.TypeAsString != "Paragraph" ? "Description" : "Texte"}
                    multiline
                    rows={2}
                    required={this.state.addFieldData.TypeAsString != "Paragraph" ? false : true}
                  />
                  :
                  ""
              }

              <Dropdown
                label="Le type d'informations figurant dans cette question est :"
                selectedKey={this.state.addFieldData.TypeAsString != undefined ? this.state.addFieldData.TypeAsString : "Text"}
                onChange={this.onChangeColumn}
                placeholder="Select an option"
                options={[
                  { key: "Text", text: "Une seule ligne de texte" },
                  { key: "Note", text: "Plusieurs lignes de texte" },
                  { key: "Number", text: "Nombre" },
                  { key: "Choice", text: "Choix" },
                  // { key:"", text: "Devise ($, ¥, €)" },
                  { key: "DateTime", text: "Date et heure" },
                  // { key:"", text: "Recherche (informations déjà sur ce site)" },
                  { key: "Boolean", text: "Oui/Non" },
                  // { key: "User", text: "Personne ou groupe" },
                  // { key: "URL", text: "Lien hypertexte" },
                  // { key:"", text: "Valeur calculée (calcul basé sur d'autres colonnes)" },
                  // { key:"", text: "Image" },
                  // { key:"", text: "Résultat de la tâche" },
                  // { key:"", text: "Données externes" },
                  // { key: "TaxonomyFieldType", text: "Métadonnées gérées" },
                  { key: "Title", text: "Titre" },
                  { key: "Paragraph", text: "Paragraphe" },
                  { key: "File", text: "Pièce jointe" }
                ]}
                required={true}
                disabled={this.state.panelInfo.mode != "add" && (this.state.formInfo.status != "brouillon" || (this.state.selectedQuestion != null && this.state.selectedQuestion.internalName.startsWith(this.state.typeFormList.find(type => type.name == this.state.formInfo.formType).params.columnStartWith) ==  true)) ? true : false}
              />
              {this.fieldsQuestions(this.state.addFieldData.TypeAsString)}
              {
                this.state.addFieldData.TypeAsString != "Title" && this.state.addFieldData.TypeAsString != "Paragraph" ?
                  <Toggle defaultChecked={this.state.addFieldData.Required != undefined && this.state.addFieldData.Required != null ? this.state.addFieldData.Required : false}
                    label="Exiger que cette question contienne des informations :"
                    onText="Oui"
                    offText="Non"
                    onChange={this.onChange("Required")}
                    // disabled={this.state.panelInfo.mode != "add" && this.state.formInfo.status != "brouillon"}
                  />
                  :
                  ""
              }
            </form>
          </Panel>

          <Dialog
            hidden={this.state.deleteDialogParams.hideDialog}
            onDismiss={this.closeDeleteDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: this.state.deleteDialogParams.type == "question" ? 'Supprimer la question' : "Supprimer le formulaire",
              closeButtonAriaLabel: 'Fermer',
            }}
            modalProps={{
              isBlocking: true
            }}
          >
            {
              this.state.deleteDialogParams.type == "question" ?
                <React.Fragment>
                  <div>Êtes vous sur de vouloir supprimer cette question et toutes ses données ?</div>
                  <DialogFooter>
                    <PrimaryButton disabled={this.state.loading.deleteAnswer} onClick={this.deleteQuestion(this.state.selectedQuestion, this.state.formInfo)}>
                      {
                        this.state.loading.deleteAnswer ?
                          <Spinner size={SpinnerSize.small} />
                          :
                          <React.Fragment>Supprimer</React.Fragment>
                      }
                    </PrimaryButton>
                    <DefaultButton disabled={this.state.loading.deleteAnswer} onClick={this.closeDeleteDialog} text="Annuler" />
                  </DialogFooter>
                </React.Fragment>
                :
                this.state.deleteDialogParams.type == "confirmForm" ?
                  this.state.deleteDialogParams.error == true ?
                    <p>Une erreur est survenue lors de la suppression du formulaire</p>
                    :
                    this.state.deleteDialogParams.loading == true ?
                      <Spinner size={SpinnerSize.large} label="Suppression du formulaire en cours" labelPosition="bottom" />
                      :
                      this.state.deleteDialogParams.cantDelete == true ?
                        <div>
                          <p>Impossible de supprimer le formulaire car des workflows rattachés à ce formulaire ne sont pas terminés : </p>
                          <DetailsList
                            items={this.state.deleteDialogParams.wfLists}
                            compact={true}
                            columns={[{
                              key: 'Workflow',
                              name: 'Workflow',
                              fieldName: 'name',
                              minWidth: 210,
                              isRowHeader: false,
                              isResizable: false,
                              isSorted: false,
                              isSortedDescending: false,
                              sortAscendingAriaLabel: 'Trier de A à Z',
                              sortDescendingAriaLabel: 'Trier de Z à A',
                              data: 'string',
                              isPadded: false,
                              onRender: (doc: IDBDocument) => (
                                <a href={"/document?w=" + doc.WfProject.id} style={{ cursor: "pointer" }}>{doc.WfProject.name}</a>
                              )
                            }]}
                            selectionMode={SelectionMode.none}
                            getKey={(item: any, index?: number) => {
                              return item.key;
                            }}
                            setKey="none"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={false}
                          />
                          <DialogFooter>
                            <PrimaryButton onClick={this.closeDeleteDialog} text="Fermer" />
                          </DialogFooter>
                        </div>
                        :
                        <React.Fragment>
                          <p>Vous allez supprimer un formulaire, toutes les données citées ci dessous seront supprimées :</p>
                          <ul>
                            <li>Circuits</li>
                            <li>Tâches</li>
                            <li>Documents</li>
                            <li>Post-it</li>
                            <li>Réponses au formulaire</li>
                          </ul>
                          <DialogFooter>
                            <PrimaryButton onClick={this.deleteForm(this.state.formInfo)} text="Confirmer" />
                            <DefaultButton onClick={this.closeDeleteDialog} text="Annuler" />
                          </DialogFooter>
                        </React.Fragment>
                  :
                  <React.Fragment>
                    <div>Êtes vous sur de vouloir supprimer le formulaire ?</div>
                    <DialogFooter>
                      <PrimaryButton onClick={this.openConfirmDeleteDialog} text="Supprimer" />
                      <DefaultButton onClick={this.closeDeleteDialog} text="Annuler" />
                    </DialogFooter>
                  </React.Fragment>
            }
          </Dialog>


          <Dialog
            hidden={this.state.publishModalLoadingParams.hidePublishDialog}
            onDismiss={this.closePublishDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: 'Sélectionner vos options de publication',
              closeButtonAriaLabel: 'Fermer'
            }}
            modalProps={{
              isBlocking: true
            }}
            maxWidth="600px"
          >
            {
              this.state.publishModalLoadingParams.error == true ?
                <p>{this.state.publishModalLoadingParams.errorMessage}</p>
                :
                this.state.publishModalLoadingParams.loading ?
                  <Spinner size={SpinnerSize.large} label="Chargement en cours..." labelPosition="bottom" />
                  :
                  <React.Fragment>
                    <TreeMenu
                      data={this.state.formInfo.formType == "lyflow" ? this.state.treeModeles.filter(model => model.isGRC != true && model.isForm != true) : this.state.treeModeles.filter(model => model.isForm == true && model.formType == this.state.formInfo.formType)}
                      onClickItem={this.onClickModele("publish")}
                    />
                    <Toggle 
                      defaultChecked={this.state.formInfo.displayed}
                      label={this.state.typeFormList.find(type => type.name == this.state.formInfo.formType) != undefined ? "Rendre le formulaire disponible dans l'outil " + this.state.typeFormList.find(type => type.name == this.state.formInfo.formType).label : "Rendre le formulaire disponible dans l'outil "}
                      onText="Oui"
                      offText="Non"
                      onChange={this.onChangeFormInfo("displayed")}
                    />
                    <DatePicker
                      label="Date de fermeture de l'offre"
                      firstDayOfWeek={DayOfWeek.Monday}
                      strings={DayPickerStrings}
                      placeholder="Séléctionner une date..."
                      ariaLabel="Séléctionner une date..."
                      value={this.state.formInfo.date_fermeture != null ?  new Date(this.state.formInfo.date_fermeture) : null}
                      formatDate={this.formatDate}
                      onSelectDate={this.onSelectDate("dateFermeture")}
                      borderless
                      disabled={this.state.loading.form == true ? true : false}
                      className=""
                    />
                    <DialogFooter>
                      <PrimaryButton disabled={this.state.publishModalLoadingParams.publishButton || this.state.publishModalLoadingParams.selectedModel == null} onClick={this.publish}>
                        {
                          this.state.publishModalLoadingParams.publishButton ?
                            <Spinner size={SpinnerSize.small} />
                            :
                            <React.Fragment>Publier</React.Fragment>
                        }
                      </PrimaryButton>
                      <DefaultButton disabled={this.state.publishModalLoadingParams.publishButton} onClick={this.closePublishDialog} text="Annuler" />
                    </DialogFooter>
                  </React.Fragment>
            }
          </Dialog>

          <Dialog
            hidden={this.state.modelCircuitParamsDialog.hideCircuitDialog}
            onDismiss={this.closeCircuitModeleDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: 'Circuit à associer au formulaire',
              closeButtonAriaLabel: 'Fermer'
            }}
            modalProps={{
              isBlocking: true
            }}
            maxWidth="600px"
          >
            {
              this.state.modelCircuitParamsDialog.error == true ?
                <p>{this.state.modelCircuitParamsDialog.errorMessage}</p>
                :
                this.state.modelCircuitParamsDialog.loading ?
                  <Spinner size={SpinnerSize.large} label="Chargement en cours..." labelPosition="bottom" />
                  :
                  <React.Fragment>
                    <TreeMenu
                      data={this.state.formInfo.formType == "lyflow" ? this.state.treeModeles.filter(model => model.isGRC != true && model.isForm != true) : this.state.treeModeles.filter(model => model.isForm == true && model.formType == this.state.formInfo.formType)}
                      onClickItem={this.onClickModele("editModel")}
                    />

                    <DialogFooter>
                      <PrimaryButton disabled={this.state.modelCircuitParamsDialog.saveButton || this.state.modelCircuitParamsDialog.selectedModel == null} onClick={this.updateFormModel}>
                        {
                          this.state.modelCircuitParamsDialog.saveButton ?
                            <Spinner size={SpinnerSize.small} />
                            :
                            <React.Fragment>Associer</React.Fragment>
                        }
                      </PrimaryButton>
                      <DefaultButton disabled={this.state.modelCircuitParamsDialog.saveButton} onClick={this.closeCircuitModeleDialog} text="Annuler" />
                    </DialogFooter>
                  </React.Fragment>
            }
          </Dialog>

          <Dialog
            hidden={this.state.duplicateDialog.hideDuplicateDialog}
            onDismiss={() => this.openCloseDuplicateFormDialog(false)}
            dialogContentProps={{
              type: DialogType.normal,
              title: "Dupliquer le formulaire",
              closeButtonAriaLabel: 'Fermer'
            }}
            modalProps={{
              isBlocking: true
            }}
          >
            {
              this.state.duplicateDialog.error == true ?
                <p>{this.state.duplicateDialog.errorMessage}</p>
                :
                this.state.duplicateDialog.loading == true ?
                  <Spinner size={SpinnerSize.large} label="Duplication du formulaire en cours..., redirection sur le fomulaire dupliqué" labelPosition="bottom" />
                  :
                  <React.Fragment>
                    <p>Voulez vous vraiment dupliquer le formulaire ?</p>
                    <DialogFooter>
                      <PrimaryButton onClick={this.duplicateForm} text="Dupliquer" />
                      <DefaultButton onClick={() => this.openCloseDuplicateFormDialog(false)} text="Annuler" />
                    </DialogFooter>
                  </React.Fragment>
            }
          </Dialog>
        </div>
      </div>
    );
  }
}

export default withRouter(CreateForm);