import * as React from 'react';
import { css } from 'office-ui-fabric-react/lib/Utilities';
import styles from './styles/DraggableComponent.module.scss';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Draggable } from "react-beautiful-dnd";
import { IColumnInfo } from '../interfaces/IRenderForm';

export interface IDraggableComponentProps {
  index: number;
  itemKey: string;
  question: IColumnInfo;
  deleteQuestion: (type: string, question?: IColumnInfo) => (event: any) => void;
  openPanel: (mode: "add" | "edit", question?: IColumnInfo) => (event: any) => void;
  columnSystemStartWith: string;
  formStatus: string;
}


export default class DraggableComponent extends React.Component<IDraggableComponentProps> {
  constructor(props:IDraggableComponentProps) {
    super(props);
  }

  public getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // styles we need to apply on draggables
    ...draggableStyle,
    opacity: isDragging ? 0.5 : 1,
  });

  public render() {
    return (
      <Draggable key={this.props.itemKey} draggableId={this.props.itemKey} index={this.props.index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={this.getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
            className={styles.draggableComponent}
          >
            {this.props.children}
            {
              this.props.question.internalName != undefined && this.props.question.internalName != null && this.props.question.internalName.startsWith("fdplyform_") ?
                <React.Fragment></React.Fragment>
                :
                <div className={css(styles.toolbar)}>
                  <button onClick={this.props.openPanel("edit", this.props.question)} type='button' className={css('ard-draggableComponent', styles.button)} title="Modifier la question" >
                    <Icon iconName="Edit" />
                  </button>
                  {
                    this.props.question.internalName != undefined && this.props.question.internalName != null && this.props.question.internalName.startsWith(this.props.columnSystemStartWith) ?
                    <React.Fragment></React.Fragment>
                    :
                      <button type='button' className={css('ard-draggableComponent', styles.button)} title="Supprimer la question"
                        onClick={this.props.deleteQuestion("question", this.props.question)}>
                        <Icon iconName='Delete' />
                      </button>
                  }
                </div>
            }
          </div>
        )}
      </Draggable>
    );
  }
}