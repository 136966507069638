import * as React from 'react'
import { getAllUsers } from "../store/store";
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import axios from 'axios'
import Store from '../store/store'
import { observer } from "mobx-react";
import Modal from "@material-ui/core/Modal/Modal";
import PopoverGroup from '../popover/PopoverGroup';
const modal = require("../../styles/styles.module.scss");
const styles = require('./styles/ParamGroup.scss');

interface Props {

}
interface State {
    allUsersList: any[]
    allGroup?: any[]
    openModal ?: boolean
    idUserGroupe ?: number
    isOpenPopover?: boolean
    userOfGroupPopover?: number[]
    idGroup ?: number

    

}

@observer

export default class ListGroupes extends React.Component<Props, State>{

    constructor(props: any) {
        super(props)
        this.state = {
            allUsersList: undefined,
            allGroup: [],
            openModal : false,
            idUserGroupe : undefined,
            userOfGroupPopover : [],
            isOpenPopover : false,
            idGroup : undefined
        }
        getAllUsers();
        

    }
    componentDidMount() {
        this.getAllGroup()
    }

    onClickButtonValidation() {

    }

    onNewGroup = () => {
        Store.contextualmenu.content = "addNewGroup";
        Store.contextualmenu.open = "extended";

        // Initialisation nécessaire pour le menu
    }
    onEditGroup = (id: string) => {
        axios
        .get(Store.wsPath + "/1/users/getUserById", { params: { id: id } })
        .then(response => {
          Store.myUser = {
            id: response.data.id,
            civility: response.data.civility,
            lastname: response.data.lastname,
            firstname: response.data.firstname,
            matricule: response.data.matricule,
            email: response.data.email,
            login: response.data.login,
            password: response.data.password,
            id_service: response.data.id_terme_service, // john
            id_fonction: response.data.id_terme_fonction, // john
            id_profession: response.data.id_terme_profession, // john
            typeUserLydoc: response.data.typeUserLydoc,
            typeUser: response.data.typeUser,
            accessConsult: response.data.accessConsult, // john
            accessRef: response.data.accessRef, // john
            accessParam: response.data.accessParam,
            accessSignalement: response.data.accessSignalement,
            id_fonctionResponsable: { ActionReferent: [], InfoResponsable: [] },
            id_professionResponsable: { ActionReferent: [], InfoResponsable: [] },
            id_serviceResponsable: { ActionReferent: [], InfoResponsable: [] }, 
            id_riskResponsable: { ActionReferent: [], InfoResponsable: [] }, 
            isGroup : response.data.isGroup,
            usersOfGroup : response.data.usersOfGroup
          };
          Store.contextualmenu.content = "EditGroup";
          Store.contextualmenu.open = "extended";
        });
    }

    
    onDeleteGroup = () => {
        axios.post(Store.wsPath + '/1/paramGroupes/deleteGroupe', {
            idUser: this.state.idUserGroupe
        }).then((response) => {
            if (response.status == 200) {
                this.modalClose();
                // Snack Bar
                Store.snackBar.message = "Groupe supprimé avec succés";
                Store.snackBar.error = false;
                Store.snackBar.open = true;

                this.getAllGroup();
            }
            else {
                // Snack Bar
                Store.snackBar.message = "Une erreur c'est produite, veuillez réessayer";
                Store.snackBar.error = true;
                Store.snackBar.open = true;
            }
        })
    }
    

    convertUser = (users) => {
        let result = ""
        if(users != undefined && users != null) {
            const result = (
                <div>{users.map((element, index) => {
                    const tempUserFind = Store.allUsers.find(user => user.id == element)
                    if (tempUserFind != undefined || tempUserFind != null) {
                        if (index == 0) {
                            return (<span title={tempUserFind.email}>{tempUserFind.firstname + ' ' + tempUserFind.lastname}</span>);
                        }
                        else {
                            return (<span title={tempUserFind.email}>{", " + tempUserFind.firstname + ' ' + tempUserFind.lastname}</span>);
                        }
                    }
                    else {
                        return "";
                    }
                })
                }
                </div>
            )
        }
        return result;
    }

    public toolbox = (cell: any, row: any)  =>  {
        return (
          <div className={styles.listActionContainer}>
            <PopoverGroup row={row} mode={"edit"} updateListAfterEdit={this.getAllGroup} />
            <div className={styles.iconEdit} onClick={() => this.onEditGroup(row.id)} title="Editer le groupe"><Icon style={{fontSize : 20}}>mode_edit</Icon></div>
            <div className={styles.iconEdit} onClick={() => this.modalOpen(row.id)}title="Supprimer le groupe"><Icon style={{fontSize : 20}}>delete</Icon></div>
          </div>
        );
    }

    modalOpen = (id?: number) => {
        this.setState({ openModal: true, idUserGroupe: id });
      };

      modalClose = () => {
        this.setState({ openModal: false, idUserGroupe: undefined });
    }

    public getAllGroup = () => {
        axios.get(Store.wsPath + '/1/paramGroupes/getAllGroupe', {}).then(response => {
            this.setState({allGroup : response.data})
        }).catch(error => {
            Store.snackBar.message = "Une erreur c'est produite, veuillez réessayez"
            Store.snackBar.open = true;
            Store.snackBar.error = true;
        })
    }
    public render() {
        //console.log('userOfGroupPopover', this.state.userOfGroupPopover)
        const options = {
            sortIndicator: true,
            defaultSortName: 'id',
            noDataText: 'Aucun groupe à afficher',
            sizePerPage: 25,
        };
        return (
            <div className={styles.mainContent}>
                <div className={styles.titleParam} style={{ background: Store.themeParam.backgroundPrimary }}><h5>Paramètres des groupes :</h5></div>
                {/* <div className={styles.groupContent}>
                </div> */}
                <div className={styles.groupContent}>
                <Button onClick={this.onNewGroup} variant="contained" color="primary"><Icon>add</Icon>Ajouter un nouveau groupe</Button>
                <BootstrapTable
                    multiColomnSearch
                    data={this.state.allGroup}
                    searchPlaceholder='Rechercher dans la liste'
                    search={true}
                    options={options}
                    pagination version='4'
                    bordered={true}
                    className={styles.contentMyTasks}
                >
                    <TableHeaderColumn isKey={true} dataField='id' hidden dataAlign='center' headerAlign='center'  >id</TableHeaderColumn>
                    <TableHeaderColumn datasort={true} dataField='firstname' dataAlign='center' headerAlign='center' width='18%' >Nom du groupe</TableHeaderColumn>
                    <TableHeaderColumn headerAlign='center' width='3%' dataField='id' tdStyle={{padding: '0', verticalAlign : 'inherit'}} dataFormat={this.toolbox.bind(this)}>Actions</TableHeaderColumn>
                </BootstrapTable>
                </div>
                <Modal
                    open={this.state.openModal}
                    className={modal.modal}
                >
                    <div className={modal.modalContent}>
                        <Typography variant="h5" id="modal-title">
                            Suppression
                        </Typography>
                        <br />
                        <Typography variant="subtitle2" id="simple-modal-description">
                            Vous allez supprimer ce groupe, êtes vous sûr de cette action ?
                        </Typography>
                        <br />
                        <div className={modal.modalButton}>
                            <Button
                                onClick={this.modalClose}
                                className={modal.button}
                                variant="contained"
                                color="secondary"
                            >
                                Annuler
                            </Button>
                            <Button
                                className={modal.button}
                                onClick={this.onDeleteGroup}
                                variant="contained"
                                color="primary"
                            >
                                Valider
                            </Button>
                        </div>
                    </div>
                </Modal>
                
            </div>  
        )
    }
}


