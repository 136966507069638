import * as React from 'react'
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import 'simplebar/dist/simplebar.min.css';
const styles = require('./styles/parameterAutoStart_styles.scss');
import Store from '../store/store'
import axios from 'axios'
import { observer } from "mobx-react";
import FileExplorer from "../FileExplorer/FileExplorer";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";


interface Props {
}

interface State {
    allLibraries: any[];
    loadingData: boolean;
    loadingButton: boolean;
}

@observer
export default class ParameterAutoStart extends React.Component<Props, State>{

    public constructor(props: Props) { // Dans le constructeur, on initialise toujours les states
        super(props)
        this.state = {
            allLibraries: [],
            loadingData: true,
            loadingButton: false
        }
    }

    componentDidMount() {
        this.getConfigAutoStart();
    }

    public onchange = (result) => {
        this.setState({ allLibraries: result });
    }

    private formatLink = (cell, row) => {
        let link: any = "";
        switch (row.type) {
            case "library":
                const sitePath: string = row.sitePath;
                let sitePathFormated: string = row.sitePath
                if (sitePath.endsWith("/") == true) {
                    sitePathFormated = sitePath.slice(0, sitePath.length - 1);
                }
                link = <a href={sitePathFormated +"/"+ row.libraryName} target="_blank">{row.label}</a>
                break;
            default:
                break;
        }
        return link;
    }

    private toolbox = (cell, row) => {
        return (
            <div style={{ textAlign: "center" }}>
                <Icon onClick={this.deleteLibrary(row)} style={{ cursor: "pointer" }}>delete</Icon>
            </div>
        )
    }

    private deleteLibrary = (row: any) => event => {
        const allLib: any[] = this.state.allLibraries.filter(lib => lib.key != row.key);
        this.setState({ allLibraries: allLib });
    }

    // Sauvegarde la liste des bibliothèques séléctionnées en base
    private saveConfigAutoStart = () => {
        this.setState({ loadingButton: true }, () => {
            axios.post(Store.wsPath + "/1/autoStart/createOrUpdateConfig", {
                title: "autoStart",
                value: this.state.allLibraries
            }).then(responseConfig => {
                if (responseConfig.status == 200 && responseConfig.data.value != undefined && responseConfig.data.value != null) {
                    this.setState({ allLibraries: responseConfig.data.value, loadingButton: false });
                    Store.snackBar.message = "La configuration a été sauvegardée.";
                    Store.snackBar.error = false;
                    Store.snackBar.open = true;
                }
            }).catch(error => {
                console.error("Error 'saveConfigAutoStart' : ", error);
                this.setState({ loadingButton: false });
                Store.snackBar.message = "Erreur lors de la sauvegarde de la configuration";
                Store.snackBar.error = true;
                Store.snackBar.open = true;
            });
        });
    }

    // Get all selected bibliothèques
    private getConfigAutoStart = () => {
        this.setState({ loadingData: true }, () => {
            axios.get(Store.wsPath + "/1/autoStart/getConfigByTitle", {
                params: {
                    title: "autoStart"
                }
            }).then(responseConfig => {
                if (responseConfig.status == 200 && responseConfig.data.value != undefined && responseConfig.data.value != null && responseConfig.data.value.length > 0) {
                    this.setState({ allLibraries: responseConfig.data.value, loadingData: false });
                }
                else {
                    this.setState({ loadingData: false });
                    Store.snackBar.message = "Pas de configuration pour le moment";
                    Store.snackBar.error = true;
                    Store.snackBar.open = true;
                }
            }).catch(error => {
                this.setState({ loadingData: false });
                console.error("Error 'getConfigAutoStart' : ", error);
                Store.snackBar.message = "Erreur lors de la récupération de la configuration";
                Store.snackBar.error = true;
                Store.snackBar.open = true;
            });
        });
    }


    private formatIfExist = (cell, row) => {
        if(cell == false) {
            return (
                <div style={{ textAlign: "center" }}>
                <Icon title="Vérifier si la liste ou le site sélectionné existe toujours" style={{ cursor: "pointer", color:"#ff9800" }}>warning</Icon>
            </div>
            );
        }
        else {
            return (
                <div style={{ textAlign: "center" }}>
                <Icon title="La liste ou le site séléctionné existe" style={{ cursor: "pointer", color:"green" }}>check</Icon>
            </div>
            );
        }
    }

    public render() {
        return (
            <div className={styles.firstContent}>
                <div className={styles.paramTitle} style={{ background: Store.themeParam.backgroundPrimary }}>
                    <h5>Paramètres démarrage auto :</h5>
                </div>
                <div className={styles.paramAutoStartContent}>
                    <FileExplorer isMultiSelect={true} showFolders={false} onChange={this.onchange} contentTypeFilterName={"Sherpa_Lyflow"} value={this.state.allLibraries} />
                    <h5 className={styles.title}>Liste des bibliothèques sélectionnées :</h5>
                    <BootstrapTable
                        className={styles.resultTab}
                        multiColumnSearch
                        data={this.state.allLibraries}
                        searchPlaceholder="Rechercher"
                        search={true}
                        pagination
                        version="4"
                        ref="table"
                        options={{
                            noDataText: this.state.loadingData == true ? "Chargement en cours..." : "Pas de listes selectionnées"
                        }}
                    >
                        <TableHeaderColumn isKey={true} hidden dataField="key">
                            Key
                        </TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{ padding: '0', verticalAlign: 'inherit' }} width='40px' headerAlign='center' dataAlign='center' dataSort={true} dataField="exist" dataFormat={this.formatIfExist}></TableHeaderColumn>
                        <TableHeaderColumn headerAlign='center' dataAlign='center' dataSort={true} dataField="libraryName">
                            Bibliothèque
                        </TableHeaderColumn>
                        <TableHeaderColumn headerAlign='center' dataAlign='center' dataSort={true} dataField="siteName">
                            Site
                        </TableHeaderColumn>
                        <TableHeaderColumn headerAlign='center' dataAlign='center' dataSort={true} dataField="label" dataFormat={this.formatLink}>
                            Lien
                        </TableHeaderColumn>
                        <TableHeaderColumn headerAlign='center' width='10%' dataField='id' tdStyle={{ padding: '0', verticalAlign: 'inherit' }} dataFormat={this.toolbox}>
                            Actions
                        </TableHeaderColumn>
                    </BootstrapTable>

                    <Button
                        type="button"
                        // className={styles.btnUser + " primaryColor"}
                        variant="contained"
                        color="primary"
                        onClick={this.saveConfigAutoStart}
                        disabled={this.state.loadingButton}
                    >
                        {this.state.loadingButton == true ?
                            "Chargement..."
                            :
                            "Sauvegarder"
                        }
                    </Button>
                </div>
            </div>
        )
    }
}