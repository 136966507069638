import * as React from 'react'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from '@material-ui/core/Icon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
const styles = require('./styles/fileExplorer.scss');
import { observer } from "mobx-react";
import axios from "axios";
import Store from '../store/store';


export interface FileExplorerProps {
    isMultiSelect: boolean;
    showFolders: boolean;
    onChange: (items: any) => void;
    value?:any[];
    contentTypeFilterName?:string;
}

export interface FileExplorerState {
    treeData?: any[];
    selectedItems?: any[];
    showFileExplorer?: boolean;
}

@observer
export default class FileExplorer extends React.Component<FileExplorerProps, FileExplorerState> {
    constructor(props: FileExplorerProps) {
        super(props);
        this.state = {
            treeData: [{
                key: "loadingsite010203",
                label: "Chargement de la liste des sites en cours...",
                isOpen: false,
                // isSelected: false,
                path: "#",
                sitePath: "#",
                type: "site",
                blocSelect: true,
                hideIcon: true
            }],
            selectedItems: this.props.value != undefined && this.props.value != null && this.props.value.length > 0 ? this.props.value : [],
            showFileExplorer: false
        };
    }

    componentDidMount() {
       this.getAllSitesSharepoint();
    }

    componentDidUpdate(prevProps:FileExplorerProps, prevState:FileExplorerState) {
        if(this.props.value != undefined && this.props.value != null && prevProps.value != undefined && prevProps.value != null && this.props.value.length != prevProps.value.length && this.props.value.length != this.state.selectedItems.length)
        {
            this.setState({selectedItems:this.props.value});
        }
    }


    // Fil d'ariane pour l'explorateur de fichier
    public breadCrumbs = (itemSelected: any, multi: boolean, text?: string, separator?: string) => {
        const container = [];
        const textBefore: string = text != undefined && text != null ? text : "Sélection :";
        const separatorChar: string = separator != undefined && separator != null ? separator : " / ";

        container.push(<span key={"emptyBread"}>{textBefore}</span>);

        if (itemSelected.length > 0 && itemSelected[0].key != undefined && itemSelected[0].key != null && multi != true) {
            const item:any = itemSelected[0];
            switch (item.type) {
                case "folder":
                    const folderSplit:string[] = item.path.split(item.libraryPath)[1].split("/").filter(folderName => folderName.length > 0);
                    container.push(<React.Fragment><span key={item.siteName + "breadCrumbs"}>{item.siteName}</span><span key={item.libraryName + "separator"}>{separatorChar}</span><span key={item.libraryName + "breadCrumbs"}>{item.libraryName}</span></React.Fragment>);
                    folderSplit.forEach((folderName:string, index:number) => {
                        container.push(<React.Fragment><span key={folderName + "separator" + index}>{separatorChar}</span><span key={folderName + "breadCrumbs"}>{folderName}</span></React.Fragment>);
                    });
                    break;
                case "library":
                    container.push(<React.Fragment><span key={item.siteName + "breadCrumbs"}>{item.siteName}</span><span key={item.libraryName + "separator"}>{separatorChar}</span><span key={item.libraryName + "breadCrumbs"}>{item.libraryName}</span></React.Fragment>);
                    break;
                default:
                    container.push(<React.Fragment><span key={item.label + "breadCrumbs"}>{separatorChar}</span><span>{item.label}</span></React.Fragment>);
                    break;
            }
        }

        return container
    }

    // Récupére tous les sites du SharePoint connecté
    public getAllSitesSharepoint = () => {
        return new Promise<void>((resolve, reject) => {
            axios.get(Store.wsPath + '/1/workflows/getAllSitesSharepoint').then((response) => {
                if (response.data.length > 0) {
                    const allSite = response.data.map((site, index) => {
                        return {
                            key: "site" + site.title + index,
                            label: site.title,
                            isOpen: false,
                            // isSelected: false,
                            nodes: [
                                {
                                    key: "loadingSite" + site.title + index,
                                    label: "Chargement...",
                                    isOpen: false,
                                    // isSelected: false,
                                    hideIcon: true,
                                    blocSelect: true
                                }
                            ],
                            // parents: [],
                            path: site.path,
                            sitePath: site.path,
                            siteName: site.title,
                            type: "site"
                        }
                    });
                    this.setState({ treeData: allSite });
                    resolve();
                }
                else {
                    this.setState({
                        treeData: [{
                            key: "nosite",
                            label: "Pas de site disponibles",
                            isOpen: false,
                            // isSelected: false,
                            path: "#",
                            sitePath: "#",
                            type: "site",
                            blocSelect: true,
                            hideIcon: true
                        }]
                    })
                    reject();
                }
            }).catch(error => {
                console.error("Error get all site SharePoint : ", error);
                this.setState({
                    treeData: [{
                        key: "errorsite",
                        label: "Une erreur est survenue lors de la récupération des sites, veuillez réessayer",
                        isOpen: false,
                        // isSelected: false,
                        path: "#",
                        sitePath: "#",
                        type: "site",
                        blocSelect: true,
                        hideIcon: true
                    }]
                });
                reject();
            })

        });
    }


    public getAllLibraryJJ = (site) => {
        axios.get(Store.wsPath + '/1/workflows/getAllBibliotheque', { params: { webUrl: site.path, contentType: this.props.contentTypeFilterName } }).then((response) => {
            if (response.data.length > 0) {
                // const parents = [...site.parents];
                // const findParent = parents.filter(parent => parent.key == site.key);
                // if (findParent.length == 0) { parents.push(site) }
                const allLibrary = response.data.map((library, index) => {
                    // const isSelected: boolean = this.state.selectedItems.filter(it => it.key == ("library" + library.Title + site.label)).length > 0 ? true : false;
                    const nodes = {};
                    if (this.props.showFolders == true) {
                        nodes["nodes"] = [
                            {
                                key: "loadingLibrary" + library.Title + index,
                                label: "Chargement...",
                                isOpen: false,
                                // isSelected: false,
                                hideIcon: true,
                                blocSelect: true
                            }
                        ];
                    }

                    return {
                        key: "library" + library.Title + site.label,
                        label: library.Title,
                        isOpen: false,
                        // isSelected: isSelected,
                        ...nodes,
                        // parents: parents,
                        path: library.ServerRelativeUrl,
                        sitePath: site.path,
                        siteName: site.label,
                        libraryPath: library.ServerRelativeUrl,
                        libraryName: library.Title,
                        libraryId: library.Id,
                        type: "library"
                    }
                });
                this.insertIntoList(site, allLibrary);
            }
            else {
                const noLibraries = [
                    {
                        key: "library" + "noLib" + site.label,
                        label: "Pas de bibliothèques de documents disponibles",
                        isOpen: false,
                        // isSelected: false,
                        path: "#",
                        type: "library",
                        hideIcon: true,
                        blocSelect: true
                    }
                ]
                this.insertIntoList(site, noLibraries);
            }
        }).catch(error => {
            console.error("Erreur lors de la récupération des librairies pour le site '" + site.label + "' : ", error);
            const noLibraries = [
                {
                    key: "library" + "noLib" + site.label,
                    label: "Erreur lors de la récupération des librairies, veuillez réessayer",
                    isOpen: false,
                    // isSelected: false,
                    path: "#",
                    type: "library",
                    hideIcon: true,
                    blocSelect: true
                }
            ]
            this.insertIntoList(site, noLibraries);
        });
    }

    // Fonction récursive de la fonction insertIntoList
    public insertIntoListChildren = (key, items, allLibraries) => {
        items.forEach(item => {
            if (item.key == key) {
                item["nodes"] = allLibraries;
            }
            else {
                if (item.nodes != undefined && item.nodes.length > 0) {
                    this.insertIntoListChildren(key, item.nodes, allLibraries);
                }
            }
        });

    }

    // Fonction qui permet d'insérer des éléments dans la data (dans le state) en fonction de l'élément sélectionné
    public insertIntoList = (item, allLibraries) => {
        const allItems = this.state.treeData;
        allItems.forEach(it => {
            if (it.key == item.key) {
                it["nodes"] = allLibraries;
            }
            else {
                if (it.nodes != undefined && it.nodes.length > 0) {
                    this.insertIntoListChildren(item.key, it.nodes, allLibraries);
                }
            }

        });
        this.setState({ treeData: allItems });

    }

    public getAllFolders = (item) => {
        axios.get(Store.wsPath + '/1/workflows/getAllFolderInLibrary', { params: { libraryUrl: item.path, siteUrl: item.sitePath } })
            .then(async (response) => {
                if (response.data.length > 0) {
                    // const parents = [...item.parents];
                    // const findParent = parents.filter(parent => parent.key == item.key);
                    // if (findParent.length == 0) { parents.push(item) }
                    const allFolders = response.data.map((folder, index) => {
                        // const isSelected: boolean = this.state.selectedItems.filter(it => it.key == ("folder" + item.sitePath + folder.Name + index + item.label)).length > 0 ? true : false;
                        return {
                            key: "folder" + item.sitePath + folder.Name + index + item.label,
                            label: folder.Name,
                            isOpen: false,
                            // isSelected: isSelected,
                            nodes: [
                                {
                                    key: "loadingFolder" + item.sitePath + folder.title + index,
                                    label: "Chargement...",
                                    isOpen: false,
                                    // isSelected: false,
                                    hideIcon: true,
                                    blocSelect: true
                                }
                            ],
                            // parents: parents,
                            path: folder.ServerRelativeUrl,
                            sitePath: item.sitePath,
                            siteName: item.siteName,
                            libraryPath: item.libraryPath,
                            libraryName: item.libraryName,
                            libraryId: item.libraryId,
                            type: "folder"
                        }
                    });
                    this.insertIntoList(item, allFolders);
                }
                else {
                    const noFolders = [
                        {
                            key: "folder" + "noFolder" + item.label,
                            label: "Pas de dossiers disponibles",
                            isOpen: false,
                            // isSelected: false,
                            path: "#",
                            type: "folder",
                            hideIcon: true,
                            blocSelect: true
                        }
                    ]
                    this.insertIntoList(item, noFolders);
                }
            }).catch(error => {
                console.error("Erreur lors de la récupération des dossiers, veuillez réessayer : ", error);
                const noFolders = [
                    {
                        key: "folder" + "noFolder" + item.label,
                        label: "Erreur lors de la récupération des dossiers, veuillez réessayer",
                        isOpen: false,
                        // isSelected: false,
                        path: "#",
                        type: "folder",
                        hideIcon: true,
                        blocSelect: true
                    }
                ]
                this.insertIntoList(item, noFolders);
            });
    }

    // Fonction pour la récursivitée
    public collapseChildren = (nodes: any[], key: string) => {
        nodes.forEach(item => {
            if (item.key == key) {
                item.isOpen = !item.isOpen;
            }
            if (item.nodes != undefined && item.nodes.length > 0) {
                this.collapseChildren(item.nodes, key);
            }
        });
    }

    // Fonction qui permet d'ouvrir les éléments enfants de l'item séléctionné mais aussi d'appeler les webServices correspondant à l'élément sélectionné
    public collapseItem = (item: any) => {
        if (item.type != undefined && item.isOpen != undefined && item.isOpen == false) {
            switch (item.type) {
                case "site":
                    this.getAllLibraryJJ(item);
                    break;
                case "library":
                    this.getAllFolders(item);
                    break;
                case "folder":
                    this.getAllFolders(item);
                    break;
                default:
                    break;
            }
        }

        const listItems: any[] = [...this.state.treeData];
        listItems.forEach(it => {
            if (it.key == item.key) {
                it.isOpen = !it.isOpen;
            }
            if (it.nodes != undefined && it.nodes.length > 0) {
                this.collapseChildren(it.nodes, item.key);
            }
        });
        this.setState({ treeData: listItems });
    }

    // Fonction récurssive pour la selection (affichage)
    // public selectedItemChildren = (key: string, items: any[], multi: boolean) => {
    //     items.forEach(item => {
    //         if (item.key == key) {
    //             item.isSelected = true;
    //         }
    //         else {
    //             if (multi != true) {
    //                 item.isSelected = false;
    //             }
    //         }

    //         if (item.nodes != undefined && item.nodes.length > 0) {
    //             this.selectedItemChildren(key, item.nodes, multi);
    //         }
    //     });
    // }

    // Fonction qui permet de selectionner l'item cliqué, va aussi mettre à jour l'objet dans la data
    public selectItem = (itemClicked: any, items: any[], multi: boolean) => {
        // const itemsModified: any[] = [...items];
        // itemsModified.forEach(item => {
        //     if (item.key == itemClicked.key) {
        //         item.isSelected = true;
        //     }
        //     else {
        //         if (multi != true) {
        //             item.isSelected = false;
        //         }
        //     }

        //     if (item.nodes != undefined && item.nodes.length > 0) {
        //         this.selectedItemChildren(itemClicked.key, item.nodes, multi);
        //     }
        // });

        let tempSelected = [...this.state.selectedItems];
        if (multi == true) {
            tempSelected.push({ ...itemClicked });
        }
        else {
            tempSelected = [{ ...itemClicked }];
        }

        this.setState({ selectedItems: tempSelected }, () => {
            this.sendDataCallback(tempSelected);
        });
    }

    // Fonction pour faire remonter les infos au parent
    public sendDataCallback = (itemsSelected: any[]) => {
        const tempResult:any[] = itemsSelected.map(item => {
            const it:any = {...item};
            if (it.nodes != undefined && it.nodes != null) {
                delete it.nodes;
            }
            return it;
        });
        this.props.onChange(tempResult);
    }

    // Fonction récurssive pour la selection (affichage)
    // public unSelectedItemChildren = (key: string, items: any[]) => {
    //     items.forEach(item => {
    //         if (item.key == key) {
    //             item.isSelected = false;
    //         }
    //         // else {
    //         //     item.isSelected = false;
    //         // }

    //         if (item.nodes != undefined && item.nodes.length > 0) {
    //             this.unSelectedItemChildren(key, item.nodes);
    //         }
    //     })
    // }

    // Fonction qui permet de selectionner l'item cliqué, va aussi mettre à jour l'objet dans la data
    public unSelectItem = (itemClicked: any, items: any[]) => {
        // const itemsModified: any[] = [...items];
        // itemsModified.forEach(item => {
        //     if (item.key == itemClicked.key) {
        //         item.isSelected = false;
        //     }

        //     if (item.nodes != undefined && item.nodes.length > 0) {
        //         this.unSelectedItemChildren(itemClicked.key, item.nodes);
        //     }
        // });
        const tempSelected: any[] = this.state.selectedItems.filter(it => it.key != itemClicked.key);
        // tempSelected.push({ ...itemClicked });
        this.setState({ selectedItems: tempSelected },() => {
            this.sendDataCallback(tempSelected);
        });
    }

    // Render qui affiche l'explorateur de fichier
    public constructjj = (items, padding?: boolean) => {
        return (
            <List
                component="div"
                aria-labelledby="nested-list-subheader"
                style={padding == true ? { paddingLeft: "20px" } : {}}
                disablePadding
                className={styles.listFileExplorer}
            >
                {items.map((item,index) => {
                    const paramListItem = {};
                    const isSelected = this.state.selectedItems.filter(it => it.key == item.key).length > 0 ? true :false;
                    paramListItem["selected"] = isSelected;
                    ((item.blocSelect != undefined && item.blocSelect == true) || (item.type != undefined && item.type == "site")) ? "" : isSelected == true ? paramListItem["onClick"] = () => this.unSelectItem(item, this.state.treeData) : paramListItem["onClick"] = () => this.selectItem(item, this.state.treeData, this.props.isMultiSelect);
                    return (
                        <React.Fragment key={"fragment"+item.key+index}>
                            <ListItem key={item.key+index} {...paramListItem} className={styles.listItemFileExplorer} button>
                                {item.hideIcon != undefined && item.hideIcon == true ?
                                    ""
                                    :
                                    <ListItemIcon><Icon>{item.type == "site" ? "public" : item.isOpen != undefined && item.isOpen == true ? "folder_open" : "folder"}</Icon></ListItemIcon>
                                }
                                <ListItemText className={styles.listItemTextFileExplorer} primary={item.label} />
                                {item.nodes != undefined && item.nodes.length > 0 ?
                                    item.isOpen != undefined && item.isOpen == true ?
                                        (
                                            <Icon className={styles.FEIcon} onClick={(event) => {
                                                event.stopPropagation();
                                                this.collapseItem(item);
                                            }}>expand_less</Icon>
                                            // <ExpandLess onClick={() => this.collapseItem(item)} />
                                        ) : (
                                            <Icon className={styles.FEIcon} onClick={(event) => {
                                                event.stopPropagation();
                                                this.collapseItem(item);
                                            }}>expand_more</Icon>
                                            // <ExpandMore onClick={() => this.collapseItem(item)} />
                                        )
                                    :
                                    ""
                                }
                            </ListItem>
                            {item.nodes != undefined && item.nodes.length > 0 ?
                                <Collapse in={item.isOpen != undefined && item.isOpen == true ? true : false} timeout="auto" unmountOnExit>
                                    {this.constructjj(item.nodes, true)}
                                </Collapse>
                                :
                                ""
                            }
                        </React.Fragment>
                    )
                })
                }
            </List>
        )
    }





    render() {
        return (
            // <Collapse in={this.state.showFileExplorer} timeout="auto" unmountOnExit>
            <div className={styles.fileExplorerContainer}>
                <div className={styles.breadCrumbsContainer}>
                    {this.breadCrumbs(this.state.selectedItems, this.props.isMultiSelect)}
                </div>
                <div className={styles.fileExplorer}>
                    <SimpleBar>
                        {this.constructjj(this.state.treeData)}
                    </SimpleBar>
                </div>
            </div>
            // </Collapse>
        );
    }
}