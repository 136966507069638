import axios from "axios";
import React from "react";
import { io } from "socket.io-client";
import Spinner from "../Spinner/Spinner";
import Store from '../store/store';
import Message from "./components/Message";
import { IMessage } from "./interfaces/Messages";
import Icon from "@material-ui/core/Icon";
const styles = require("./styles/styles.scss");
interface Props {
    idForm: number;
}

interface State {
    messages: IMessage[],
    loading: boolean,
    error: {
        statut: boolean;
        message: string
    }
    myMessage: string;
    isScroll: boolean;
    missMessages: number;
    showMissMessages: boolean;
}


class Messages extends React.Component<Props, State> {

    socket = io(Store.wsPath + "/messages", {
        transports: ['websocket']
    });
    listMessages: React.RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);
        this.state = {
            messages: [],
            loading: false,
            error: {
                statut: false,
                message: null
            },
            myMessage: "",
            isScroll: false,
            missMessages: 0,
            showMissMessages: false
        };
        this.listMessages = React.createRef();
    }

    componentDidMount(): void {
        axios.get(Store.wsPath + "/1/messages/getAllMessagesFormById", {
            params: {
                idForm: this.props.idForm
            }
        })
            .then(response => {
                this.setState({
                    messages: response.data,
                    loading: false
                }, () => {
                    this.listMessages.current.addEventListener('scroll', this.onScrollMessage)
                });
            })
            .catch(error => {
                this.setState({
                    error: {
                        statut: true,
                        message: "Une erreur s'est produite lors de la récupération des messages."
                    },
                    loading: false
                })
                console.error("Error request 'getAllMessageFormById' : " + error)
            })
        this.socket.on("connect", () => {
            this.socket.emit('messageFormRoom', this.props.idForm)
        })
        this.socket.on("disconnect", (reason) => {
            
        })
        this.socket.on("connect_error", (error) => {
            console.error("Socket.io connection error : ", error)
        })
        this.socket.on('messageForm', this.listenerSoccket)
    }
    componentDidUpdate(prevProps: Props, prevState) {
        if (prevState.messages.length != this.state.messages.length) {
            this.scrollToBottom();
        }
        if (prevProps.idForm != this.props.idForm) {
            this.socket.emit('exitMessageFormRoom', prevProps.idForm);
            this.socket.emit('messageFormRoom', this.props.idForm)
            axios.get(Store.wsPath + "/1/messages/getAllMessagesFormById", {
                params: {
                    idForm: this.props.idForm
                }
            })
                .then(response => {
                    this.setState({
                        messages: response.data,
                        loading: false
                    }, this.scrollToBottom);
                })
                .catch(error => {
                    this.setState({
                        error: {
                            statut: true,
                            message: "Une erreur s'est produite lors de la récupération des messages."
                        },
                        loading: false
                    })
                    console.error("Error request 'getAllMessageFormById' : " + error)
                })
        }
    }
    componentWillUnmount(): void {
        this.socket.close();
    }
    listenerSoccket = (message: IMessage) => {
        const tempMessages = [...this.state.messages];
        tempMessages.push(message);
        var tempNbMissMessages = this.state.missMessages;
        var tempShowMissMessages = this.state.showMissMessages;
        if (this.state.isScroll) {
            tempNbMissMessages++;
            tempShowMissMessages = true;
        }
        this.setState({ messages: tempMessages, missMessages: tempNbMissMessages, showMissMessages: tempShowMissMessages }, this.scrollToBottom);
    }
    myMessageOnChange = (event) => {
        event.preventDefault();
        this.setState({
            myMessage: event.target.value
        })
    }
    sendMessage = (event) => {
        event.preventDefault();
        const newMessage: IMessage = {
            content: this.state.myMessage,
            idForm: this.props.idForm,
            idUser: Store.userConnected.id,
            createdAt: new Date().toString()
        };
        this.socket.emit('messageForm', newMessage)
        const tempMessages = this.state.messages
        tempMessages.push(newMessage);
        this.setState({
            myMessage: "",
            messages: tempMessages
        }, () => this.scrollToBottom());
        
    }

    scrollToBottom = (isScroll = this.state.isScroll) => {
        if (isScroll == false) { // Bizarre de check à false a retravailler
            this.listMessages.current.scrollTop = this.listMessages.current.scrollHeight;
            this.setState({ missMessages: 0 })
        }
    }

    onScrollMessage = (event) => {
        if (this.state.isScroll == false && event.srcElement.scrollTop < event.srcElement.scrollTopMax) {
            this.setState({ isScroll: true });
        } else if (event.srcElement.scrollTop == event.srcElement.scrollTopMax) {
            this.setState({ isScroll: false, showMissMessages: false });
        }
    }
    render() {
        return (
            <div className={styles.messages}>
                {
                    this.state.error.statut == true ?
                        <span className="errorMessage">{this.state.error.message}</span>
                        :
                        this.state.loading == true ?
                            <Spinner label="Récupération des échanges en cours... ⏳" />
                            :
                            <>
                                {this.state.isScroll ?
                                    <div className={styles.missMessagesBox}>
                                        <div className={styles.missMessages} onClick={() => this.scrollToBottom(false)}>
                                            {this.state.missMessages > 1 ?
                                                <span className={styles.missMessagesSpan}>{this.state.missMessages + " nouveaux messages"}</span>
                                                : this.state.missMessages == 1 ?
                                                    <span className={styles.missMessagesSpan}>{this.state.missMessages + " nouveau message"}</span>
                                                    : ""}
                                            <Icon className={styles.sendIcon}>keyboard_arrow_down</Icon>
                                        </div>
                                    </div>
                                    : ''}
                                <div className={styles.listMessages} id="listMessages" ref={this.listMessages}>

                                    {this.state.messages.length > 0 ?
                                        this.state.messages.map((message, index) => {
                                            return <Message message={message} key={index} />
                                        })
                                        : <span style={{ marginLeft: "5px" }}>Aucun message, soyez le premier.</span>}
                                </div>
                            </>
                }
                <form onSubmit={this.sendMessage} className={styles.form}>
                    <input type="text" name="message" id="messageInput" value={this.state.myMessage} onChange={this.myMessageOnChange} className={styles.textInput} />
                    <button type="submit" className={styles.submitMessage}><Icon className={styles.sendIcon}>send</Icon></button>
                </form>
            </div>
        );
    }
}

export default Messages;